import { UniqueIdentifier } from "@dnd-kit/core";
import { MeasureBreakdownResult, MeasureSummaryResults, Screenline, WeightedIntersectingSegment } from "api/analytics";
import { Geometry } from "geojson";
import { ReactNode } from "react";

import { CheckboxDropdownItem } from "components";

export * from "./license";

export enum SegmentDirection {
  N = "N",
  NE = "NE",
  E = "E",
  SE = "SE",
  S = "S",
  SW = "SW",
  W = "W",
  NW = "NW",
}

export const LEFT_SIDE_DIRECTIONS = ["S", "SE", "SW", "W"];

export enum DialogType {
  CREATE = "CREATE",
  RENAME = "RENAME",
  COPY = "COPY",
  DELETE = "DELETE",
}

export enum ROUTES {
  Dashboard = "/dashboard",
  Map = "/map",
  Datasets = "/datasets",
  DatasetEditor = "/datasets/:datasetId/edit",
  Documentation = "/docs",
  Exports = "/exports",
  Analytics = "/analytics",
  SelectLink = "/analytics/select-link",
  SelectLinkEditor = "/analytics/select-link/:configId/edit",
  CorridorDiscovery = "/analytics/corridor-discovery",
  RoadVmt = "/analytics/road-vmt",
}

export enum DOCS_ROUTES {
  Home = "/",
  Tutorials = "tutorials",
  Webinars = "webinars",
  Product = "product",
  DataQuality = "data-quality",
  Glossary = "glossary",
  Faq = "faq",
  Terms = "terms",
}

export enum DOCS_PRODUCT_ROUTES {
  License = "license",
  Export = "export",
  DatasetDefinition = "dataset-definition",
  CorridorDiscovery = "corridor-discovery",
  SelectLink = "select-link",
  Screenlines = "screenlines",
}

export enum ShapesInputFormat {
  SHAPEFILE = "shapefile",
  GEOJSON = "geojson",
}

export interface Permission {
  allow: boolean;
  reason: string | null;
}

export interface Permissions {
  [key: string]: Permission;
}

// TODO move API types to the respective files (e.g. api/analytics/index.d.ts), rename types to match backend type name

export interface Area {
  id: string;
  name?: string;
  population?: number;
  stateName?: string;
}

export type Flow = {
  id: string;
  value: number;
  lat?: number;
  lon?: number;
  level: string;
  areaName?: string;
  levelName?: string;
  countryName?: string;
  external?: boolean;
  isGate?: boolean;
};

export interface AtedBy {
  id: number;
  email: string;
}

/**
 * A measure column with numerical values that can be aggregated. Values are stored for combinations of dimension categories, which can be used for filtering.
 *
 * @param columnName The name of the column storing the measure values in the data mart table
 * @param label The human-readable label for the measure
 * @param help A description of the measure
 * @param dimensions The dimensions that can be used to filter the measure, and for which breakdowns can be calculated
 * @param enabled Indicates if the measure is enabled/licensed for the current user
 * @param iconName The name of the icon to use for the measure
 */
export interface Measure {
  columnName: string;
  label: string;
  help: string;
  dimensions: Dimension[];
  enabled: boolean;
  iconName?: string;
}

/**
 * Measure for origin-destination (OD) flows
 */
export interface ODMeasure extends Measure {}

/**
 * Measure for road VMT (vehicle miles traveled)
 */
export interface RoadVmtMeasure extends Measure {}

/**
 * Measure for a corridor dataset (edge counts or node counts)
 */
export interface CorridorMeasure extends Measure {}

/**
 * The type of logical road network (vehicular or pedestrian) - this defines a subset of the physical network
 * (filtering based on road class and 'P' values in segment direction')
 */
export enum RoadNetworkType {
  Pedestrian = "pedestrian",
  Vehicular = "vehicular",
}

/**
 * The default road network type if undefined
 */
export const DEFAULT_ROAD_NETWORK_TYPE = RoadNetworkType.Vehicular;

/**
 * Measure for road volumes (AADT, pedestrians etc.)
 *
 * @param directional Indicates if the measure may have different values for different driving directions on the same road feature. If
 *   `false` the per-segment measure values for a road feature are summed to derive the non-directional value.
 * @param supportsScreenlines
 *   Indicates if the measure supports screenlines, i.e. if screenline controls should be shown in the UI and if the
 *   screenlines/screenline-counts endpoint can be queried with this measure. Default: false
 * @params supportsIntersectionVolumes
 *  Indicates if the measure supports road intersections volumes.
 * @param network
 *   The type of road network (vehicular or pedestrian) to which this measure applies
 */
export interface RoadsMeasure extends Measure {
  directional: boolean;
  supportsScreenlines?: boolean;
  supportsIntersectionVolumes?: boolean;
  network: RoadNetworkType;
}

/**
 * Dimension of a measure, used for filtering and breakdowns
 *
 * @param columnName The name of the column storing the dimension categories in the data mart table
 * @param label The human-readable label for the dimension
 * @param help A description of the dimension
 * @param categories The categories of the dimension
 * @param enabled Indicates if the dimension is enabled/licensed for the current user
 * @param iconName The name of the icon to use for the dimension
 */
export interface Dimension {
  columnName: string;
  label: string;
  help: string;
  categories: DimensionCategory[];
  enabled: boolean;
  iconName?: string;
}

/**
 * An individual category of a dimension
 *
 * @param value The value of the category
 * @param label The human-readable label for the category (default: use the value)
 */
export interface DimensionCategory {
  value: string;
  label?: string;
}

export interface DatasetResponse {
  id: string;
  name: string;
  description: string;
  gateCount: number;
  zoningLevel: string;
  measures: Measure[];
  timePeriod: string;
  createdAt: string;
  createdBy: AtedBy;
  updatedAt: string;
  updatedBy: AtedBy;
  datasetId?: string;
}

export interface DatasetMetadata {
  tileService: ODTileService;
  measures: Measure[];
  level: string;
  roadsTileService: DirectionalRoadsTileService;
  zoningLevels: ZoningLevel[];
  exportPermissions: {
    allowExport: boolean;
  };
}

export interface DatasetCountsArguments {
  measure: string;
  level?: string;
  queryType: QueryType;
  filter?: FilterArguments;
  areaOfInterest?: string[] | null;
  compression?: string;
}

export interface ZoneIdsArguments {
  timePeriod?: string;
  level?: string;
  areaOfInterest: string[] | null;
}

export interface DatasetCountsByZoneIdArguments extends DatasetCountsArguments {
  selectedId: string;
  isGate: boolean;
}

export interface Counts {
  zones: Map<string, number>;
  gates: Map<string, number>;
  availableRange?: ODMeasureRange;
}

export interface CountsByZoneId {
  counts: Counts;
  selectedZoneId: string;
}

export interface ZoneStats {
  sqKm: number;
  pop?: number; // population
}

export interface SelectedZone {
  zoneId: string;
  name?: string;
}

export interface ZoneIdAreaPairs {
  zoneIds: { id: string; sqKm: number; pop?: number }[];
}

export interface DatasetGate {
  identifier: string;
  description: string;
  segments: GateSegment[];
  lon: number;
  lat: number;
}

export interface Segment {
  from: number | undefined;
  to: number | undefined;
  id: string;
}

/**
 * Metadata for a directional roads tile service (with to-from and direction fields)
 */
export interface DirectionalRoadsTileService {
  url: string;
  layerName: string;
  fromToSegmentIdField: string;
  fromToSegmentIndexField: string;
  toFromSegmentIdField: string;
  toFromSegmentIndexField: string;
  facilityTypeField: string;
  nameField: string;
  fromToDirectionField: string;
  toFromDirectionField: string;
  isSegmentPairField: string;
  roadClassFilter: number[];
}

// TODO consider adding minZoom and maxZoom to the API response
export interface ExtendedDirectionalRoadsTileService extends DirectionalRoadsTileService {
  minZoom: number;
  maxZoom: number;
}

/**
 * Metadata for non-directional roads tile service (no to-from or direction fields)
 */
export interface NonDirectionalRoadsTileService {
  url: string;
  layerName: string;
  fromToSegmentIdField: string;
  fromToSegmentIndexField: string;
  facilityTypeField: string;
  nameField: string;
  roadClassFilter: number[];
}
// TODO consider adding minZoom and maxZoom to the API response
export interface ExtendedNonDirectionalRoadsTileService extends NonDirectionalRoadsTileService {
  minZoom: number;
  maxZoom: number;
}

export interface ODTileService {
  url: string;
  layers: ODTileLayer[];
  minZoom: number;
  maxZoom: number;
}

export interface ODTileLayer {
  name: string;
  idField: string;
  level: string;
  minzoom: number;
  maxzoom: number;
  areaSqKmField: string;
  url: string;
  nameField?: string;
  populationField?: string;
}

export type ZoneDetails = {
  zoneId: string; // depreated (remove and redirect usages from zoneId to id, after merging the refactor)
  id: string;
  level: string;
  lat: number;
  lon: number;
  measure: string;
  summaries: {
    [queryType in QueryType.OUTGOING | QueryType.INCOMING]: MeasureSummaryResults;
  };
  topFlows: {
    [flowsDirection in QueryType.OUTGOING | QueryType.INCOMING]: {
      flows: Flow[];
    };
  };
  isGate?: boolean;
};

export type RoadVmtZoneDetails = {
  id: string;
  name?: string;
  measure: string;
  summary: {
    unfilteredTotal: number;
    filteredTotal: number;
    breakdowns: MeasureBreakdownResult[];
  };
};

export type ZoneDetailsArguments = {
  zoneId: string;
  level: string;
  timePeriod: string;
  measure: string;
  filter: FilterArguments;
  summaries: {
    [flowsDirection in QueryType]?: MeasureSummaryArgs;
  };
  topFlows: {
    [flowsDirection in QueryType]?: FlowsSettings;
  };
};

export type DatasetZoneDetailsArguments = Omit<ZoneDetailsArguments, "timePeriod">;

export type FilterArguments = Record<string, string[]>;

export type ODCountsArguments = {
  level?: string;
  timePeriod: string;
  measure: string;
  queryType: QueryType;
  filter: FilterArguments;
  areaOfInterest: string[] | null;
  compression?: string;
};

export type ODCountsByZoneIdArguments = ODCountsArguments & {
  selectedZoneId: string;
};

export type ODMetadataArguments = {
  timePeriod: string;
  includeDisabled: boolean;
};

export type SubareaStateArguments = {
  polygon: Geometry;
  timePeriod: string;
  gateRoadClasses: number[];
  level: string;
  includeSegmentGeometry?: boolean;
  customZoningId?: string;
};

export interface GateCandidate {
  fromToSegId: string;
  toFromSegId: string;
  roadClass?: number;
  geometry: Geometry;
}

export type SubareaState = {
  zoneIds: string[];
  gateCandidates: GateCandidate[];
};

export interface SubareaPolygonArguments {
  timePeriod: string;
  simplification: Simplification;
  customZoningId?: string;
  level?: string;
  zoneIds?: string[];
}

export interface Simplification {
  distanceM: number;
}

export interface SubareaPolygon {
  polygon: Geometry;
  rawVertexCount: number;
  vertexCount: number;
  areaSqKm: number;
}

export interface GenerateGatesArguments {
  polygon: Geometry;
  timePeriod: string;
  gateRoadClasses: number[];
  existingGates: Gate[];
  maxGroupingDistanceM?: number;
}

export interface Gate {
  identifier: string;
  description: string;
  segments: GateSegment[];
  lon: number;
  lat: number;
  pinned?: boolean;
}

export interface GateSegmentProps {
  id: string;
  roadClass: number;
  direction: SegmentDirection;
  name: string;
}

export interface GateSegment {
  from: number;
  to: number;
  id: string;
  roadClass: number;
  direction: SegmentDirection;
  name: string;
}

export interface ParsedGateSegment extends GateSegment {
  toFromSeg: GateSegment | undefined;
}

export interface AddGateArguments {
  intersectingLine: Geometry;
  existingGates: Gate[];
  timePeriod: string;
  gateRoadClasses: number[];
  includeUnchanged: boolean;
  polygon: Geometry | null;
}

export interface AddGateResponse {
  addedGate: Gate;
  deletedGates: Gate[];
  updatedGates: Gate[];
  unchangedGates: Gate[];
}

export interface GateCoordinatesArguments {
  segments: { id: string }[];
  polygon: Geometry | null;
  timePeriod: string;
}

export interface GateCoordinates {
  lat: number;
  lon: number;
  segmentNodes: Record<string, { from: number; to: number }>;
}

export interface ZoningLevel {
  id: string;
  shortNameSingular: string;
  shortNamePlural: string;
  longNameSingular: string;
  longNamePlural: string;
}

export type ODMetadata = {
  tileService: ODTileService;
  measures: ODMeasure[];
  level: string;
  zoningLevels: ZoningLevel[];
  exportPermissions: {
    allowExport: boolean;
  };
};

export interface ExportCommonOptions {
  shouldGenerateSeqIds: boolean;
}

export type ExportJob = {
  status: ExportJobStatus;
  uuid: string;
  createdAt: string;
  completedAt: string;
  url: string;
  email: string;
  exportType: ExportType;
  datasetId?: string;
  areaOfInterestName: string;
  exportDescription: string;
  datasetName: string;
  od: ExportJobOdDetails;
  road: ExportJobRoadDetails;
  selectLink: SelectLinkAoiExportConfig;
  roadVmt: RoadVmtAoiExportConfig;
  commonOptions: ExportCommonOptions;
  timePeriod: string;
};

export type ExportJobs = {
  jobs: ExportJob[];
};

export type NumZonesResponse = {
  counts: NumZones[];
};

export type NumZones = {
  level: string;
  num: number;
};

export interface ODDatasetExportRequest {
  measure: string;
  level: string | undefined;
  filter?: FilterArguments;
  roadFilter?: FilterArguments;
  areaOfInterest?: string[] | null;
  compression?: string;
  shouldGenerateSeqIds: boolean;
  shouldExportZones: boolean;
  roadClassesToExport?: number[];
  shouldExportVolumeBreakdowns: boolean;
  shouldExportOdBreakdowns: boolean;
  exportDescription: string;
  areaOfInterestName: string;
  roadMeasure: string;
  screenlinesToExport?: Screenline[] | null;
  shouldExportIntersections?: boolean;
  shouldExportIntersectionBreakdowns?: boolean;
}

export interface AoiExportRequest {
  exportDescription: string;
  areaOfInterestName: string;
  timePeriod: string;
  areaOfInterest?: string[] | null;
  shouldGenerateSeqIds: boolean;
  od?: OdAoiExportConfig;
  road?: RoadAoiExportConfig;
  selectLink?: SelectLinkAoiExportConfig;
  roadVmt?: RoadVmtAoiExportConfig;
}

/**
 * A labeled dimension, part of export job details returned by backend.
 */
export interface DimensionDetails {
  value: string;
  label: string;
}

/**
 * A labeled dimension category, part of export job details returned by backend.
 */
export interface CategoryDetails {
  value: string;
  label: string;
}

/**
 * Export filters, part of export job details returned by backend.
 */
export type ExportFilters = Record<string, CategoryDetails[]>;

/**
 * OD export parameters passed by frontend when submitting an export job.
 */
export interface OdAoiExportConfig {
  measures: {
    [measureName: string]: {
      filter: FilterArguments;
    };
  };
  level: string | undefined;
  shouldExportZones: boolean;
}

/** OD details in a submitted export job */
export interface ExportJobOdDetails {
  measures: {
    [measureName: string]: MeasureDetails;
  };
  level: string | undefined;
  shouldExportZones: boolean;
}

/**
 * Measure information in export job details
 */
export interface MeasureDetails {
  label: string;
  filter: ExportFilters;
  dimensions?: DimensionDetails[];
}

/**
 * Road export parameters passed by frontend when submitting an export job.
 */
export interface RoadAoiExportConfig {
  measures: {
    [measureName: string]: {
      filter: FilterArguments;
    };
  };
  shouldExportSegments: boolean;
  shouldExportVolumeBreakdowns: boolean;
  shouldExportVolumes: boolean;
  roadClassesToExport?: number[] | RoadClass[] | null;
  screenlinesToExport?: Screenline[] | null;
  shouldExportIntersections?: boolean;
  shouldExportIntersectionBreakdowns?: boolean;
}

/**
 * Road configuration details for an export job returned by the backend.
 *
 * Corresponds to `ExportJobDetails.LabeledRoad` in backend
 *
 * @param screenlineCount The number of exported screenlines
 */
export interface ExportJobRoadDetails {
  measures: {
    [measureName: string]: MeasureDetails;
  };
  shouldExportSegments: boolean;
  shouldExportVolumeBreakdowns?: boolean;
  shouldExportIntersections?: boolean;
  shouldExportIntersectionBreakdowns?: boolean;
  shouldExportVolumes?: boolean;
  roadClassesToExport?: RoadClass[];
  screenlineCount?: number;
}

export interface SelectLinkAoiExportConfig {
  selectedSegmentsPredicate: SelectedElementsPredicate | null;
  selectedOriginsPredicate: SelectedElementsPredicate | null;
  selectedDestinationsPredicate: SelectedElementsPredicate | null;
  countsFilter: MeasureFilter;
}

export interface RoadVmtAoiExportConfig {
  measures: RoadVmtMeasures;
}

export interface RoadVmtMeasures {
  aadt_vmt: {
    filter?: FilterArguments | ExportFilters | null;
  };
  mileage: {
    filter?: FilterArguments | ExportFilters | null;
  };
}

export interface OdNumZonesRequest {
  timePeriod: string;
  areaOfInterest?: string[] | null;
  datasetId?: string | null;
}

/**
 * Request arguments for querying and locating features (segments/custom zones) on the map.
 * @param searchText The search text can contain chars 0-9, _, A-Z
 * @param timePeriod The time period i.e. 2023
 * @param searchMode The search mode, either segmentId or customZoneId
 * @param limit Optional, the maximum number of results to return (default: 8)
 */
export interface SearchRequest {
  searchText: string;
  timePeriod: string;
  searchMode: SearchMode;
  limit?: number;
}

export enum SearchType {
  SEGMENT_ID = "segment-id",
  CUSTOM_ZONE_ID = "customZoneId",
}

export type SearchMode = SearchType.SEGMENT_ID | SearchType.CUSTOM_ZONE_ID;

export interface SearchResultSegmentItem extends SearchResultItem {
  segment_idx: number;
}

/**
 * Response for the search endpoint.
 * @param features The list of search results can contain 1 to many items if succcessful, otherwise empty
 * @param status The status of the search. "unsupported_characters" on invalid search text,
 * "expecting_more_characters" if the search text is too short, "success" if the search was successful
 */

export interface SearchResponse {
  features: SearchResultItem[];
  status: SearchResultStatus;
}

export interface SearchResultItem {
  Segment: {
    id: string;
    description: string;
    markerCoordinates: { lat: number; lon: number };
    segment_idx: number;
  };
}

export enum SearchResultStatus {
  success = "success",
  expecting_more_characters = "expecting_more_characters",
  unsupported_characters = "unsupported_characters",
}

export enum ExportType {
  DATASET = "dataset",
  AOI = "aoi",
  SELECT_LINK = "aoiSelectLink",
  ROAD_VMT = "aoiRoadVMT",
}

export enum MapVisualizationType {
  OD = "0",
  ROADS = "1",
  SELECT_LINK = "2",
}

export type MapVisualizationMode =
  | MapVisualizationType.OD
  | MapVisualizationType.ROADS
  | MapVisualizationType.SELECT_LINK;

export enum SelectLinkMode {
  LINKS = "links",
  ZONES = "zones",
  RESULTS = "results",
}

export enum SelectLinkAnalysisMode {
  Configuration = "0",
  Results = "1",
}

export enum ZoneSelectionMode {
  Origins = "0",
  Destinations = "1",
}

export interface SelectLinkAnalysisOptions {
  minVolume: number;
}

export interface SelectLinkAnalysisDetails {
  analysisName: string;
  timePeriod: string;
  description?: string;
  focusAreaId?: string;
  configId?: string;
}

export enum QueryType {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}

export enum ExtendedQueryType {
  INTERNAL = "internal",
}

export enum FlowPattern {
  internal = "internal-pattern",
  gate = "gate-pattern",
  external = "external-pattern",
  counties = "counties-pattern",
  internalRound = "internal-round-pattern",
  unknown = "unknown-pattern",
}

export type FlowsSettings = {
  n: number;
  excludeExternal: boolean;
  excludeGates: boolean;
  excludeZones: boolean;
  excludeSameStartEnd: boolean;
};

export interface HoveredFlow {
  id: string;
  external: boolean;
  isGate: boolean;
}

export interface Volume {
  fromToId: string;
  toFromId: string;
  fromToIdx: number;
  toFromIdx: number;
  factype: number; // Todo add specific type
  highway: string; // Todo add specific type
  st_name: string;
  oneway: boolean;
  seg_pair: boolean;
  volumeFT: number;
  volumeTF: number;
  ft_dir: SegmentDirection;
  tf_dir: SegmentDirection;
  lngLat: number[];
}

export interface SelectedVolume {
  ftSegmentId: string;
  ftSegmentIdx: number;
  streetName: string;
  ftDirection: SegmentDirection;
  lngLat: number[];
  feature: Volume;
  tfSegmentId?: string;
  tfSegmentIdx?: number;
  tfDirection?: SegmentDirection;
}

export type RoadsMetadataArguments = {
  timePeriod: string;
  includeDisabled: boolean;
};

export type SelectLinkMetadataArguments = {
  timePeriod: string;
  includeDisabled: boolean;
};

export interface RoadClass {
  id: number;
  label: string;
  category: number;
}

export interface RoadCategory {
  id: number;
  label: string;
}

/** Metadata response for Roads
 *
 * @param vehicularRoadsTileService
 *   The tile service for vehicular road features
 * @param pedestrianRoadsTileService
 *   The tile service for pedestrian road features (if available for the time period)
 * @param measures
 *   The list of road measures
 * @param roadClasses
 *   The available road classes
 * @param featureAttributes
 *   The road feature attributes metadata, example: number lanes, etc
 * @param roadNetworkVersion
 *   Indicates the version of the road network. Segment ids are only valid within a given road network version. Note
 *   that the road network version is expressed as a year, however it may be different from the requested time period,
 *   and should never be supplied to any endpoint that expects a time period. The version should only be used as an
 *   opaque value, to compare if the networks for two time periods are compatible or not.
 * @param exportPermissions
 *   Export permissions for road, example if the export are enable or not
 * @param intersectionLevels
 *   Road intersection levels details per measure that supports them.
 *
 * @todo
 *  - move to src/api/analytics/index.d.ts
 *  - use a different type for internal state (don't reuse the API type)
 *  - exportPermissions should be used from the permissions response, eventually we want to remove the permissions on the metadata response
 */
export interface RoadsMetadataResponse {
  vehicularRoadsTileService: ExtendedDirectionalRoadsTileService;
  pedestrianRoadsTileService?: ExtendedNonDirectionalRoadsTileService;
  measures: RoadsMeasure[];
  roadClasses: RoadClass[];
  featureAttributes: MetadataFeatureAttribute[];
  roadNetworkVersion: string;
  exportPermissions: {
    allowExport: boolean;
  };
  intersectionLevels?: RoadIntersectionLevelsByMeasure;
  roadCategories: RoadCategory[];
}

export interface SelectLinkResultsTileService {
  url: string;
  layerName: string;
  fromToSegmentIdField: string;
  fromToSegmentIndexField: string;
  toFromSegmentIdField: string;
  toFromSegmentIndexField: string;
  facilityTypeField: string;
  nameField: string;
  fromToDirectionField: string;
  toFromDirectionField: string;
  isSegmentPairField: string;
  roadClassFilter?: number[];
}

export interface CustomSelectLinkResultsTileService extends SelectLinkResultsTileService {
  minZoom: number;
  maxZoom: number;
}

export interface SelectLinkMetadataResponse {
  roadsTileService: ExtendedDirectionalRoadsTileService;
  measures: Measure[];
  roadClasses: RoadClass[];
  roadCategories: RoadCategory[];
  resultsTileService: CustomSelectLinkResultsTileService;
  permissions: {
    Export: {
      allow: boolean;
      reason: string | null;
    };
    SelectLink: {
      allow: boolean;
      reason: string | null;
    };
  };
  minAllowedVolume: number;
}

export interface MetadataFeatureAttribute {
  columnName: string;
  label: string;
  attributeType: "String" | "Long" | "Double" | "Boolean";
}

/**
 * Request arguments for querying counts for segments in the entitled area (or area of interest), optionally
 * filtering to road classes that are visible at a specified zoom level.
 *
 * @param timePeriod The time period
 * @param measure The measure to aggregate
 * @param areaOfInterest The list of area ids (subset of the entitled areas) that define an area of interest
 * @param filter The dimension filters (to e.g. filter to only selected day types), as a map `column name -> List of column values`
 * @param compression The result set compression (default: gzip)
 * @param zoomLevel Optional zoom level to get segment counts only for road classes that are visible at the specified zoom level.
 * @param datasetId Optional dataset id for which to get segment counts.
 */
export interface SegmentVolumesRequest {
  timePeriod: string;
  measure: string;
  compression: string;
  areaOfInterest: string[] | null;
  filter: FilterArguments;
  zoomLevel?: number;
  datasetId?: string;
}

export interface RoadsVolumes {
  measure: string;
  maxVolume: number;
  minVolume: number;
  size: number;
}

/**
 * Request arguments for querying the indexes of segments in the entitled area (or area of interest), optionally
 * filtering to road classes that are visible at a specified zoom level.
 *
 * @param timePeriod The time period
 * @param areaOfInterest The list of area ids (subset of the entitled areas) that define an area of interest
 * @param onlyFromTo Indicates if only from-to segment ids are required (default: false)
 * @param includeReverse Indicates whether to return reverse segments for the from-to segments (default: false)
 * @param compression The result set compression (default: gzip)
 * @param zoomLevel Optional zoom level to get segment ids only for road classes that are visible at the specified zoom level.
 * @param datasetId Optional dataset id to get segment ids in its subarea.
 */
export interface SegmentIndexesRequest {
  timePeriod: string;
  areaOfInterest: string[] | null;
  onlyFromTo?: boolean;
  compression?: string;
  zoomLevel?: number;
  datasetId?: string;
}

/** Request arguments for querying segment statistics in the entitled area (or area of interest), optionally filtered to
 * the road classes visible at a specified zoom level.
 *
 * @param timePeriod
 *   The time period
 * @param areaOfInterest
 *   The list of area ids (subset of the entitled areas) that define an area of interest
 * @param zoomLevel
 *   Optional zoom level to get segment stats only for road classes that are visible at the specified zoom level
 */
export interface SegmentStatsRequest {
  timePeriod: string;
  areaOfInterest: string[] | null;
  zoomLevel?: number;
}

/** Segment statistics response
 *
 * @param segmentCount
 *   The number of segments in the area
 */
export interface SegmentStatsResponse {
  segmentCount: number;
}

export interface SegmentVolumeDetailsRequest {
  segmentIndexes: number[];
  timePeriod: string;
  measure: string;
  summary: {
    breakdowns: MeasureBreakdownArgs[];
    filteredTotal: boolean;
    unfilteredTotal: boolean;
  };
  filter: FilterArguments;
  datasetId?: string;
}

export interface SegmentVolumeDetail {
  segmentIndex: number;
  measure: string;
  summary: MeasureSummaryResults;
}

export interface SegmentFeatureDetailsRequest {
  segmentIds: string[];
  timePeriod: string;
}

export interface SegmentFeatureDetailsResponse {
  segments: SegmentFeatureDetail[];
}
export interface SegmentFeatureDetail {
  segmentId: string;
  attributes: SegmentFeatureAttribute[];
}

export interface SegmentFeatureAttribute {
  columnName: string;
  value: any;
}

export type RoadSegmentIndexesWithFactype = Map<number, { factype: number }>;

export enum RoadClassCategory {
  LIMITED_ACCESS = "limitedAccess",
  PRINCIPAL_ARTERIAL = "principalArterial",
  MAJOR_ARTERIAL = "majorArterial",
  OTHER = "other",
}

export interface RoadSegmentIndexesByRoadClass {
  [RoadClassCategory.LIMITED_ACCESS]: number[];
  [RoadClassCategory.OTHER]: number[];
}

export enum ExportJobStatus {
  Pending = 0,
  Running = 1,
  LocalExportComplete = 2,
  Uploading = 3,
  UploadComplete = 4,
  LocalExportFailed = -1,
  UploadFailed = -2,
  Expired = -99,
}

export enum ExportJobStatusLabels {
  Queued = "Queued",
  Running = "Running",
  Completed = "Completed",
  Failed = "Failed",
  Expired = "Expired",
}

export enum ExportTypeDict {
  dataset = "Dataset",
  aoi = "Area",
  aoiSelectLink = "Select Link",
  aoiRoadVMT = "Road VMT",
}

export interface Filter extends CheckboxDropdownItem {
  value: string;
}

export type FilterItems = {
  [key: string]: Filter;
};

export interface FilterGroup extends CheckboxDropdownItem {
  items: FilterItems;
  icon: ReactNode;
}

export type FiltersType = {
  [key: string]: FilterGroup;
};

export enum MeasureType {
  AADT = "aadt",
  PEDESTRIANS = "pedestrians",
  AADT_VMT = "aadt_vmt",
  MILEAGE = "mileage",
}

export enum DatasetEditorMode {
  SimpleSelect = "simple_select",
  DirectSelect = "direct_select",
  MergeUnion = "merge_union",
  MergeDifference = "merge_difference",
  DrawGate = "draw_gate",
  AddSegmentsToGate = "add_segments_to_gate",
}

export interface SelectedArea {
  id: string;
  zoom: number;
  itemType: "zone" | "gate";
  level?: string;
}

//TileService

export interface TileServiceMetadata {
  tiles: string[];
  name: string;
  format: string;
  basename: string;
  id: string;
  description: string;
  version: string;
  minzoom: number;
  maxzoom: number;
  center: number[];
  bounds: number[];
  type: string;
  generator: string;
  generator_options: string;
  vector_layers: VectorLayer[];
  tilestats: Tilestats;
  tilejson: string;
}

export interface Tilestats {
  layerCount: number;
  layers: Layer[];
}

export interface Layer {
  layer: string;
  count: number;
  geometry: string;
  attributeCount: number;
  attributes: LayerAttribute[];
}

export interface LayerAttribute {
  attribute: string;
  count: number;
  type: LayerAttributeType;
  values: Array<number | string>;
  min?: number;
  max?: number;
}

export enum LayerAttributeType {
  Number = "number",
  String = "string",
}

export interface VectorLayer {
  id: string;
  description: string;
  minzoom: number;
  maxzoom: number;
  fields: { [key: string]: VectorLayerField };
}

export enum VectorLayerField {
  Number = "Number",
  String = "String",
}
export type ZoneIds = Map<string, Map<string, ZoneStats>>;

export interface CustomDatasetRepositoryValues {
  folderName: string;
  items: CatalogItem[];
  permissions: Permissions;
}

export type CustomDatasetRepository = Record<UniqueIdentifier, CustomDatasetRepositoryValues>;

export interface DatasetFolders {
  folders: CustomDatasetRepository;
  permissions: Permissions;
}

export interface DatasetFoldersResponse {
  folders: DatasetFolder[];
  permissions: Permissions;
}

export interface DatasetFolder {
  folderId: UniqueIdentifier;
  folderName: string;
  items: CatalogItem[];
  permissions: Permissions;
  createdAt: Date;
  createdBy: TedBy;
  updatedAt: Date;
  updatedBy: TedBy;
}

export enum DatasetType {
  OD = "od",
}

export enum DatasetStatus {
  Draft = "draft",
  Computing = "computing",
  Computed = "computed",
}

export enum CatalogItemType {
  Dataset = "dataset",
  Zoning = "zoning",
  ConfigDocument = "configdocument",
}

export interface ZoningItem {
  itemType: CatalogItemType.Zoning;
  id: UniqueIdentifier;
  zoningId: UniqueIdentifier;
  name: string;
  description: string;
  createdAt: Date;
  createdBy: TedBy;
  updatedAt: Date;
  updatedBy: TedBy;
  permissions: Permissions;
}

export interface CustomDataset {
  id: UniqueIdentifier;
  itemType: CatalogItemType.Dataset;
  computedDatasetId?: UniqueIdentifier;
  name: string;
  description: string;
  licensedAreaId: number;
  datasetType: DatasetType;
  datasetStatus: DatasetStatus;
  createdAt: Date;
  createdBy: TedBy;
  updatedAt: Date;
  updatedBy: TedBy;
  permissions: Permissions;
  timePeriod: string;
}

export type ConfigDocumentType = "screenlines" | "map" | "selectlink"; // add more types as needed (also in backend)

export interface ConfigDocument {
  id: string;
  itemType: CatalogItemType.ConfigDocument;
  name: string;
  description?: string;
  configDocumentType: ConfigDocumentType;
  timePeriod?: string; // documents can optionally be stored for a specific time period
  licensedAreaId?: number; // documents can optionally have an associated licensed area
  createdAt: Date;
  createdBy?: TedBy;
  updatedAt?: Date;
  updatedBy?: TedBy;
  permissions: Permissions;
}

export type CatalogItem = ZoningItem | CustomDataset | ConfigDocument;

export interface CreateDatasetPayload {
  folderId: UniqueIdentifier;
  datasetName: string;
  licensedAreaId: string;
  timePeriod: string;
  datasetType?: string;
  customZoningId?: string;
  initializeSubarea?: boolean;
  addDefaultGates?: boolean;
  discardGates?: boolean;
}

/**
 * Arguments for creating an OD dataset configuration based on current editor settings of an existing configuration.
 * This is used when resolving a conflict, to keep both the updated configuration in the current session and the
 * configuration updated elsewhere, by creating a new configuration based on the current settings.
 *
 * @param datasetConfigId
 *   The ID of the original dataset to save under a new name. The dataset must be owned by the organization of the
 *   current user. The new dataset will be created right after the original dataset in the same folder.
 *   If the dataset has been deleted, then the new dataset will be created as the last dataset in the specified folder
 * @param timePeriod
 *   The time period of the dataset (should be the same as the original dataset)
 * @param folderId
 *   The uuid of the folder where the new dataset will be saved
 * @param areaIds
 *   The area ids of the dataset (should be the same as the original dataset)
 * @param datasetName
 *   The new dataset name
 * @param description
 *   The optional dataset description
 * @param zoningLevel
 *   Either a standard zoning level, or 'Custom' if a custom zoning id is defined
 * @param customZoningId
 *   The custom zoning id, for zoning level 'Custom'
 * @param gateRoadClasses
 *   The road classes that are considered when adding gates to the configuration. Existing gates are not affected by
 *   this setting.
 * @param gates
 *   list of gates
 * @param subAreaGeometry
 *   sub-area polygon (polygon or multi-polygon GeoJSON), optional
 */
export interface OdDatasetConfigSaveAsRequest {
  datasetConfigId: string;
  timePeriod: string;
  folderId: string;
  areaIds: string[];
  datasetName: string;
  description: string;
  zoningLevel: string;
  customZoningId?: string;
  gateRoadClasses: number[];
  gates: Gate[] | null;
  subAreaGeometry: Geometry | null;
}

export interface UpdateODDatasetConfig extends UpdateODDatasetConfigPayload {
  datasetId: string;
}

export interface UpdateODDatasetConfigPayload {
  datasetName: string;
  description: string;
  timePeriod: string;
  zoningLevel: string;
  licensedAreaId: number;
  gateRoadClasses: number[];
  gates: Gate[] | null;
  subAreaGeometry: Geometry | null;
  expectedVersion?: number;
  customZoningId?: string;
}

export interface TedBy {
  id: number;
  email: string;
}

export interface ODDatasetConfig {
  datasetId: string;
  datasetConfigId: string;
  folderId: string;
  datasetName: string;
  description: string;
  datasetStatus: DatasetStatus;
  timePeriod: string;
  zoningLevel: string;
  licensedAreaId: number;
  minZoneIntersectionRatio: number;
  gateRoadClasses: number[];
  gates: Gate[] | null;
  subAreaGeometry: Geometry | null;
  areaIds: string[];
  version: number;
  createdAt: string;
  createdBy: TedBy;
  updatedAt: string;
  updatedBy: TedBy;
  deletedAt: string;
  deletedBy: TedBy;
  permissions: Permissions;
  customZoningId?: string;
}

export interface ODDatasetValidationMessage {
  text: string;
  severity: "error" | "warning";
  context?: string;
  reference?: string;
  locations?: GateCoordinates[];
}

export interface ODDatasetValidation {
  isValid: boolean;
  messages: ODDatasetValidationMessage[];
}

export interface ODDatasetComputation {
  datasetId: string;
  computationId: string;
}

export interface GeocodingSearchResultsFeature {
  id: string;
  place_name: string;
  center: [number, number];
}

export interface GeocodingSearchResults {
  features: GeocodingSearchResultsFeature[];
}

export interface FocusAreaItem {
  id: string;
  label: string;
  areaSqKm: number;
  centroidLat: number;
  centroidLon: number;
  region: string;
  licensedAreaId: string;
  geometry: Geometry;
  population?: number;
  gateCount?: number;
  zoningLevel?: string;
  folderName?: string;
  areas?: string[];
  datasetId?: string;
  timePeriods: string[];
  isDemo?: boolean;
  enabled?: boolean;
  disabledReason?: string;
}

export interface ZoningAttribute {
  name: string;
  attributeType: string;
}

interface ZoningSchema {
  attributes: ZoningAttribute[];
  idField: string;
  areaSqKmField?: string;
  nameField?: string;
}

export enum ValidationMessageSeverity {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
}

export interface GeoCoordinates {
  id?: string;
  lat: number;
  lon: number;
}

export interface ValidationMessageDetail {
  id?: string;
  text: string;
  involvedZoneIds?: string[];
  locations?: GeoCoordinates[];
}

export interface ValidationMessage {
  text: string;
  severity: ValidationMessageSeverity;
  details?: ValidationMessageDetail[];
}

export interface ZoningValidation {
  isValid: boolean;
  messages: ValidationMessage[];
  maxSeverity?: ValidationMessageSeverity;
}

export interface TopNValue {
  value: string;
  count: number;
}

export interface AttributeStatistics {
  notNullCount: number;
  nullCount: number;
  distinctValueCount: number;
  hasUniqueValues: boolean;
  minValue?: string;
  maxValue?: string;
  avgValue?: string;
  minLength?: number;
  maxLength?: number;
  topNonUniqueValues?: TopNValue[];
}

interface ZoningStatistics {
  featureCount: number;
  nullGeometryCount: number;
  emptyGeometryCount: number;
  invalidGeometryCount: number;
  totalVertexCount: number;
  multiPartZoneCount: number;
  totalPartCount: number;
  avgVertexCount?: number;
  avgPartCount?: number;
  maxAreaSqKm?: number;
  minAreaSqKm?: number;
  maxPartCount?: number;
  minPartCount?: number;
  maxVertexCount?: number;
  minVertexCount?: number;
  avgAreaSqKm?: number;
  medianAreaSqKm?: number;
  attributes: { [key: string]: AttributeStatistics };
}

export interface Zoning {
  zoningId: string;
  crsId: number;
  crsAuthority: string;
  crsName: string;
  crsIsProjected: boolean;
  crsIsInferred: boolean;
  boundingBox: Geometry;
  schema: ZoningSchema;
  validation: ZoningValidation;
  statistics: ZoningStatistics;
  createdAt: string;
  createdBy?: AtedBy;
  updatedAt?: string;
  updatedBy?: AtedBy;
  maxZoom: number;
  minZoom: number;
}

export interface UploadZoningResponse {
  defaultName: string;
  zoning: Zoning;
}

export interface PreparedZoningConfigRequest {
  stagedZoningId: string;
  idField: string;
  nameField: string | null;
  attributes: string[];
  simplification?: FixedDistanceSimplification | VariableDistanceSimplification;
}

export interface CustomZoningSelectorItem {
  customZoningId: string;
  name: string;
  description: string;
  zoningId: string;
  tileService: ODTileLayer;
  enabled: boolean;
  disabledReason: string;
}

export interface CustomZoningFolderItem {
  folderId: string;
  folderName: string;
  customZonings: CustomZoningSelectorItem[];
}

export interface CustomZoningSelectorItemsResponse {
  folders: CustomZoningFolderItem[];
}

export interface SelectedElementsPredicate {
  isAnd: boolean;
  clauses: SelectedElementsPredicateClause[];
}

export interface SelectedElementsPredicateClause {
  isAnd: boolean;
  isNot: boolean;
  parts: SelectedElementsPredicateClausePart[];
}

export interface SelectedElementsPredicateClausePart {
  elementId: string;
  isNot: boolean;
}

export interface MeasureFilter {
  min: number;
  max: number;
}

export interface SelectLinkSegmentCountsRequest {
  timePeriod: string;
  measure: string;
  level: string;
  filter: FilterArguments;
  areaOfInterest: string[] | null;
  compression: string;
  selectedSegmentsPredicate: SelectedElementsPredicate | null;
  selectedOriginsPredicate: SelectedElementsPredicate | null;
  selectedDestinationsPredicate: SelectedElementsPredicate | null;
  precision: number;
  format: string;
  aggTotal: boolean;
  countsFilter: MeasureFilter;
}

export interface SelectLinkConfigCreationRequest {
  analysisName: string;
  description?: string | null;
  timePeriod: string;
}

export interface SelectLinkConfigUpdateRequest {
  analysisName?: string | null;
  description?: string | null;
  roadClasses?: number[] | null;
  roadFilters?: FiltersType | null;
  segmentsGroups: SegmentsGroup[] | null;
  segmentsGroupsOp: string | null;
  origins: SelectedArea[] | null;
  destinations: SelectedArea[] | null;
  minCount: number | null;
  maxCount?: number | null;
}

export interface MeasureRange {
  min: number;
  max: number;
}

export interface MeasureRangeResponse {
  range: MeasureRange;
  quantile?: number;
}

export interface MeasureRangeRequest {
  timePeriod: string;
  measure: string;
  queryType: QueryType;
  filter: FilterArguments;
  areaOfInterest: string[] | null;
  quantileLevel?: number;
}

export interface EdgesRangeRequest {
  timePeriod: string;
  measure: string;
  level: string;
  filter: FilterArguments;
  quantileLevel?: number;
}

export interface RoadMeasureRangeRequest {
  timePeriod: string;
  measure: string;
  filter: FilterArguments;
  datasetId?: string;
}

export interface ODMeasureRange {
  [key: string]: MeasureRange;
}
export interface SegmentsGroup {
  groupName: string;
  segments: SelectedSegmentConfig[];
  color: string;
}

export enum SelectLinkPredicateLogic {
  And = "0",
  Or = "1",
}

export interface SelectedSegmentConfig {
  segmentId: string;
  streetName: string;
  direction: SegmentDirection; // Compass direction, e.g. "NE"
  lon: number;
  lat: number;
  fromToId: string;
}

// An interface with fields required for export dialog and comparison of configuration changes
export interface SelectLinkConfigEssentials {
  segmentsGroups: SegmentsGroup[];
  segmentsGroupsOp: SelectLinkPredicateLogic;
  origins: SelectedArea[];
  destinations: SelectedArea[];
  minCount: number;
}

export interface SelectLinkConfig extends SelectLinkConfigEssentials {
  configId: string;
  analysisName: string;
  organizationId: number;
  licensedAreaId: number;
  timePeriod: string;
  description: string;
  roadClasses: number[];
  roadFilters: FiltersType;
  maxCount: number;
  version: number;
  createdAt: Date;
  createdBy: TedBy;
  updatedAt: Date;
  updatedBy: TedBy;
  deletedAt: Date;
  deletedBy: TedBy;
}

export interface SelectLinkConfigs {
  configs: SelectLinkConfig[];
}

export interface DistanceSimplifications {
  sqrRootAreaFactor: string;
  maxIntervalM: string;
  intervalM: string;
}

export interface FixedDistanceSimplification {
  intervalM?: number;
}

export interface VariableDistanceSimplification {
  sqrRootAreaFactor?: number;
  maxIntervalM?: number;
}

export enum DistanceTypes {
  Constant = "Constant",
  AreaDependent = "Area Dependent",
}

export interface CorridorMetadata {
  corridorLevels: Record<string, any>;
  detailLevel: string;
  measures: CorridorMeasure[];
}

export interface CorridorEdgeCountStats {
  size: number;
  maxVolume: number;
  minVolume: number;
  quantile: number;
}

export interface CorridorNodeCountStats {
  size: number;
  maxVolume: number;
}

export interface CorridorHeatmapConfigurationRequestParams {
  timePeriod: string;
  level: string;
  measure: MeasureType;
  filter: FilterArguments;
  areaOfInterest?: string[] | null;
  measureFilter?: MeasureFilter;
  parameters?: CorridorHeatmapConfigurationComputationParameterOverrides;
}

export interface CorridorHeatmapConfigurationComputationParameterOverrides {
  topFlowRatio?: number;
  edgeLengthQuantile?: number;
  edgeLengthQuantileToMaxRadiusFactor?: number;
  edgeLengthQuantileToMaxRadiusPower?: number;
  minRadiusFraction?: number;
}

export interface CorridorHeatmapConfiguration {
  radiusMin: number;
  radiusMax: number;
  radiusExponentialBase: number;
  radiusStartZoomScale: number;
  radiusEndZoomScale: number;
  intensityMin: number;
  intensityMax: number;
  intensityExponentialBase: number;
  intensityStartZoomScale: number;
  intensityEndZoomScale: number;
}

export type RoadVmtMetadataRequest = {
  timePeriod: string;
};

export interface RoadVmtMetadata {
  tileService: ODTileService;
  measures: RoadVmtMeasure[];
  detailLevel: string;
  zoningLevels: ZoningLevel[];
  exportPermissions: {
    allowExport: boolean;
  };
}

export type RoadVmtZoneCountsRequest = {
  timePeriod: string;
  measure: string;
  filter: FilterArguments;
  areaOfInterest: string[] | null;
  compression?: string;
  measureFilter?: MeasureFilter;
};

export type RoadVmtZoneDetailsRequest = {
  zoneId: string;
  timePeriod: string;
  measure: string;
  summary: MeasureSummaryArgs;
  filter: FilterArguments;
};

export type MeasureSummaryArgs = {
  breakdowns: MeasureBreakdownArgs[];
  filteredTotal: boolean;
  unfilteredTotal: boolean | null;
};

export type MeasureBreakdownArgs = {
  includeUnfiltered: boolean | null;
  dimensions: string[];
};

export interface RoadVmtZoneCounts {
  zones: Map<string, number>;
  availableRange?: ODMeasureRange;
}

export interface MapSymbol {
  color: string;
  type: string;
}

export interface CircleMapSymbol extends MapSymbol {
  style: "circle";
  size: number;
}

export interface LineMapSymbol extends MapSymbol {
  style: "solid" | "dashed";
  width: number;
}

export interface UniqueValueInfo {
  value: string;
  symbol: CircleMapSymbol | LineMapSymbol;
}

export interface ServiceOverlayRenderer {
  symbol?: CircleMapSymbol | LineMapSymbol;
  label?: string | null;
  type: string;
  uniqueValueInfos?: UniqueValueInfo[];
}

export interface MapboxLayerProperties {
  type: string;
  paint: mapboxgl.AnyPaint;
  layout?: mapboxgl.AnyLayout | null;
  minzoom: number;
  maxzoom: number;
}

export enum FeatureServiceGeometryType {
  Line = "Line",
  Point = "Point",
  Polyline = "Polyline",
  Polygon = "Polygon",
}

export interface ServiceOverlay {
  serviceLayerId: string;
  layerName: string;
  description: string;
  getFeaturesUrl: string;
  getFeatureCountUrl: string;
  getLayerMetadataUrl: string;
  layerInfoUrl: string | null;
  mapboxLayerProperties: MapboxLayerProperties;
  geometryType: FeatureServiceGeometryType;
  renderer: ServiceOverlayRenderer;
  where: string | null;
  copyrightText?: string;
  owner?: {
    name: string;
    url: string;
  };
}

export interface CorridorEdge {
  id: number;
  ft_dir: string;
  tf_dir: string;
}

export interface HighlightedFeature {
  id: string | number;
  source: string;
  sourceLayer?: string;
  layerId?: string;
}

export interface AreaAccuracyTableRequest {
  licensedAreaId: number;
  timePeriod: string;
  roadClassFilter?: number[];
  areaOfInterest?: string[];
}

export interface AreaAccuracyTableItem {
  aadtGroup: string;
  numberOfSegmentsWithCounters: number;
  rmsePercent: number | null;
  rmse: number | null;
  msd: number | null;
  mad: number | null;
  mape: number | null;
  acv: number | null;
  maxDeviationPercent: number | null;
  tooFewSegmentsWithCounters: boolean;
}

export interface AreaAccuracyTableResponse {
  accuracyData: AreaAccuracyTableItem[];
}

export type RoadClassMapping = { [key: string]: number[] };

export interface AreaAccuracyScatterPlotRequest {
  licensedAreaId: number;
  timePeriod: string;
  roadClassMapping: RoadClassMapping;
  areaOfInterest?: string[];
}

export interface AreaAccuracyScatterPlotItem {
  aadt: number;
  refCount: number;
  roadClassGroup: string;
}

/* Source of mapping between segment ids and indexes */
export enum SegmentIndexesForIdsMapSource {
  SELECT_LINK = "selectLink",
  DATASET = "dataset",
}

export enum MapLayerContainerId {
  OD = "od",
  ROADS = "roads",
  SCREENLINES = "screenlines",
  CORRIDOR_EDGES = "corridor-edges",
  CORRIDOR_HEATMAP = "corridor-heatmap",
  VMT_COUNTS = "vmt-counts",
  ROAD_INTERSECTIONS = "road-intersections",
}

// Road intersection types
type Compression = "gzip" | "none";
type ResponseFormat = "protobuf" | "csv";

export enum AggregationFunction {
  Average = "avg",
  Sum = "sum",
  // Maximum = "max", TODO 1602124 reenable after MAX is supported for cluster volumes also
}

export interface RoadIntersectionVolumes {
  measure: string;
  maxVolume: number;
  minVolume: number;
  size: number;
}

export interface RoadIntersectionClusterVolumes {
  measure: string;
  maxVolumes: Map<number, number>;
  minVolumes: Map<number, number>;
  size: number;
}

export type RoadIntersectionClusterIdDetails = Map<number, Map<number, number>>;

export interface RoadIntersectionVolumesRequest {
  timePeriod: string;
  measure: string;
  filter?: FilterArguments;
  areaOfInterest?: string[];
  compression?: Compression;
  format?: ResponseFormat;
  measureFilter?: MeasureFilter;
  nodeIds?: string[];
}

export interface RoadIntersectionNodeIdsRequest {
  timePeriod: string;
  areaOfInterest?: string[];
  datasetId?: string;
  compression?: Compression;
  format?: ResponseFormat;
}

export interface RoadIntersectionClusterVolumesRequest {
  timePeriod: string;
  measure: string;
  filter?: FilterArguments;
  areaOfInterest?: string[];
  compression?: Compression;
  format?: ResponseFormat;
  measureFilter?: MeasureFilter;
  aggregationFunction?: AggregationFunction;
}

export interface RoadIntersectionClusterIdsRequest {
  timePeriod: string;
  areaOfInterest?: string[];
  datasetId?: string;
  compression?: Compression;
  format?: ResponseFormat;
}

export interface RoadIntersectionsTileService {
  url: string;
  layerName: string;
  idField: string;
}

export interface RoadIntersectionLevel {
  maxRadius?: number;
  minZoomLevel: number;
  maxZoomLevel: number;
  tileService: RoadIntersectionsTileService;
}

export interface RoadIntersectionLevels {
  baseLevel: RoadIntersectionLevel;
  clusterLevels: RoadIntersectionLevel[];
}

export interface RoadIntersectionLevelsByMeasure {
  [measure: string]: RoadIntersectionLevels;
}

export interface RoadIntersectionVolumeDetailsRequest {
  nodeIds: number[];
  timePeriod: string;
  measure: string;
  summary: {
    breakdowns: MeasureBreakdownArgs[];
    filteredTotal: boolean;
    unfilteredTotal: boolean;
  };
  filter: FilterArguments;
  aggregationFunction?: AggregationFunction;
}

export interface RoadIntersectionVolumeDetailsResponse {
  measure: string;
  summary: MeasureSummaryResults;
}

/**
 * Object to store the weighted intersecting segments for a screenline
 */
export interface ScreenlineWeightedIntersectingSegments {
  screenlineId: string;
  weightedIntersectingSegments: WeightedIntersectingSegment[];
}
