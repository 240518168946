import { Box, Tooltip } from "@mui/material";
import { useMemoryStore } from "api/MemoryStoreContext";
import { MemoryStoreKeys } from "api/memoryStore";
import { Divider, MapControlContainer, SliderControl, VisibilityIconButton } from "components_new";
import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";

import { RangeFilter } from "features/filters/RangeFilter";
import { isInAvailableRange } from "features/filters/utils";
import { ModuleData } from "features/map/ModuleManager";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";
import { globalActions } from "store/sections/global";
import { mapActions } from "store/sections/map";

import { MapLayerContainerId, MeasureRange, RoadClassCategory } from "types";

import { getFromToSegmentIndex, getReverseSegmentIndex } from "utils/parse";

import { calculateWidthFactor, valueLabelFormat } from "../../utils";

interface RoadsMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  roadsModuleData: ModuleData;
  isSelectLinkResults: boolean;
  disabled?: boolean;
}

export const RoadsMapLayers: FC<RoadsMapLayersProps> = ({ map, roadsModuleData, isSelectLinkResults, disabled }) => {
  const dispatch = useAppDispatch();
  const memoryStore = useMemoryStore();

  const { filterRoadSegmentsByRange, changeShowRoadVolumes, changeVolumesOpacity, changeVolumesWidth } =
    roadsModuleData.data;

  const collapsedMapLayerContainers = useAppSelector((state) => state.global.collapsedMapLayerContainers);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIndexes = useAppSelector((state) => state.analytics.roadSegmentIndexes);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const showRoadVolumes = useAppSelector((state) => state.map.showRoadVolumes);
  const availableRange = useAppSelector((state) => state.filters.roadAvailableRange.data);
  const availableRangeState = useAppSelector((state) => state.filters.roadAvailableRange.state);
  const roadsRange = useAppSelector((state) => state.filters.roadRange);
  const savedOpacityFactor = useAppSelector((state) => state.analytics.roadsOpacityFactor);
  const savedWidthFactor = useAppSelector((state) => state.analytics.roadsWidthFactor);

  const intersectionIdsState = useAppSelector((state) => state.roadIntersections.intersectionIds.state);
  const intersectionVolumesState = useAppSelector((state) => state.roadIntersections.intersectionVolumes.state);
  const intersectionClusterIdsState = useAppSelector((state) => state.roadIntersections.intersectionClusterIds.state);
  const intersectionClusterVolumesState = useAppSelector(
    (state) => state.roadIntersections.intersectionClusterVolumes.state,
  );

  const selectLinkMetadata = useAppSelector((state) => state.analytics.selectLinkMetadata);

  const [opacityFactor, setOpacityFactor] = useState(savedOpacityFactor);
  const [widthFactor, setWidthFactor] = useState(savedWidthFactor);
  const [isDisabledVisibilityButton, setIsDisabledVisibilityButton] = useState(false);

  const range = useMemo<[number, number]>(
    () => (roadsRange ? roadsRange : [availableRange?.min || 0, availableRange?.max || 0]),
    [roadsRange, availableRange],
  );

  // Check if the zoom level is within the minimum zoom level of the tile service
  const checkZoomLevel = useCallback(() => {
    const minZoomLevel = isSelectLinkResults
      ? selectLinkMetadata?.data?.resultsTileService?.minZoom
      : roadsModuleData?.data?.tileService?.minZoom;

    if (map.current && minZoomLevel > 0) {
      const currentZoom = map.current.getZoom();

      setIsDisabledVisibilityButton(currentZoom < minZoomLevel);
    } else {
      setIsDisabledVisibilityButton(false);
    }
  }, [
    map,
    isSelectLinkResults,
    roadsModuleData?.data?.tileService?.minZoom,
    selectLinkMetadata?.data?.resultsTileService?.minZoom,
  ]);

  // Sets up a "zoomend" event listener to check the zoom level of the map
  useEffect(() => {
    const mapInstance = map.current;

    if (mapInstance) {
      checkZoomLevel();
      mapInstance.on("zoomend", checkZoomLevel);
    }

    return () => {
      mapInstance?.off("zoomend", checkZoomLevel);
    };
  }, [map, checkZoomLevel]);

  const loading =
    roadsVolumes.state === DataState.LOADING ||
    roadsMetadata.state === DataState.LOADING ||
    roadSegmentIndexes.state === DataState.LOADING ||
    availableRangeState === DataState.LOADING ||
    intersectionIdsState === DataState.LOADING ||
    intersectionVolumesState === DataState.LOADING ||
    intersectionClusterIdsState === DataState.LOADING ||
    intersectionClusterVolumesState === DataState.LOADING;

  const handleChangeShowRoadVolumes = () => {
    dispatch(mapActions.setShowRoadVolumes(!showRoadVolumes));
    changeShowRoadVolumes(!showRoadVolumes);
  };

  const handleFilterRoadSegmentsByRange = () => {
    if (roadsVolumes.data && roadsMetadata.data && roadSegmentIndexes.data && availableRange && range) {
      if (range[0] >= availableRange.min || range[1] <= availableRange.max) {
        const allSegmentFromToIndexes = new Set();
        const limitedAccessFromToSegmentIndexes = new Set();
        const restAccessFromToSegmentIndexes = new Set();

        const [minRange, maxRange] = range;
        const segmentVolumesData = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_VOLUMES) as Map<number, number>;
        const allLimitedAccessFromToSegmentIndexes = new Set(
          memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES_BY_ROAD_CLASS)[
            RoadClassCategory.LIMITED_ACCESS
          ],
        );
        const allAccessSegmentFromToIndexes = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES);

        if (segmentVolumesData) {
          segmentVolumesData.forEach((volume, volumeSegmentIdx) => {
            const reverseSegmentIdx = getReverseSegmentIndex(volumeSegmentIdx);
            const reverseVolume = segmentVolumesData.get(reverseSegmentIdx) || null;

            if (
              !allAccessSegmentFromToIndexes.has(volumeSegmentIdx) &&
              !allAccessSegmentFromToIndexes.has(reverseSegmentIdx)
            ) {
              return;
            }

            if (
              (volume >= minRange && volume <= maxRange) ||
              (reverseVolume && reverseVolume >= minRange && reverseVolume <= maxRange)
            ) {
              const fromToSegmentIndex = getFromToSegmentIndex(volumeSegmentIdx);

              allSegmentFromToIndexes.add(fromToSegmentIndex);

              if (allLimitedAccessFromToSegmentIndexes.has(fromToSegmentIndex)) {
                limitedAccessFromToSegmentIndexes.add(fromToSegmentIndex);
              } else {
                restAccessFromToSegmentIndexes.add(fromToSegmentIndex);
              }
            }
          });

          filterRoadSegmentsByRange(
            Array.from(allSegmentFromToIndexes.keys()).sort(),
            Array.from(limitedAccessFromToSegmentIndexes.keys()).sort(),
            Array.from(restAccessFromToSegmentIndexes.keys()).sort(),
          );
        }
      } else {
        filterRoadSegmentsByRange(null);
      }
    }
  };

  const handleSetRoadSegmentsRange = (newRange: [number, number], availableRange: MeasureRange) => {
    dispatch(filtersActions.setRoadsRange(isInAvailableRange(newRange, availableRange) ? null : newRange));
  };

  const handleChangeVolumesOpacity = (opacityFactor: number) => {
    changeVolumesOpacity(opacityFactor, isSelectLinkResults);
    dispatch(analyticsActions.setRoadsOpacityFactor(opacityFactor));
  };

  const handleChangeVolumesWidth = (widthFactor: number) => {
    changeVolumesWidth(widthFactor);
    dispatch(analyticsActions.setRoadsWidthFactor(widthFactor));
  };

  return (
    <>
      <MapControlContainer
        title="Segment Volumes"
        primaryAction={
          isDisabledVisibilityButton ? (
            <Tooltip title="Map layer is invisible at this zoom scale">
              <span style={{ display: "flex" }}>
                <VisibilityIconButton visible={showRoadVolumes} style={{ pointerEvents: "none" }} disabled />
              </span>
            </Tooltip>
          ) : (
            <VisibilityIconButton
              visible={showRoadVolumes}
              onClick={handleChangeShowRoadVolumes}
              disabled={loading || disabled}
            />
          )
        }
        collapse
        expanded={!collapsedMapLayerContainers.includes(MapLayerContainerId.ROADS)}
        onChange={() => dispatch(globalActions.toggleLayerContainerCollapsedState(MapLayerContainerId.ROADS))}
      >
        <Box padding={1}>
          {!roadsModuleData.data.isSelectLinkResults ? (
            <RangeFilter
              label="Filter"
              range={range}
              availableRange={availableRange || { min: 0, max: 0 }}
              loading={loading}
              disabled={disabled}
              filterByRange={handleFilterRoadSegmentsByRange}
              setRange={handleSetRoadSegmentsRange}
            />
          ) : null}
          <Divider sx={{ marginY: 0.5 }} />
          <SliderControl
            label="Opacity"
            disabled={loading || disabled}
            value={opacityFactor}
            defaultValue={1}
            min={0}
            max={1}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            valueLabelDisplay="auto"
            onChange={(e, value) => setOpacityFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeVolumesOpacity(value as number)}
          />
          <SliderControl
            label="Width"
            disabled={loading || disabled}
            value={widthFactor}
            defaultValue={1}
            min={0}
            max={2}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            scale={calculateWidthFactor}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            onChange={(e, value) => setWidthFactor(value as number)}
            onChangeCommitted={(e, value) => handleChangeVolumesWidth(value as number)}
          />
        </Box>
      </MapControlContainer>
    </>
  );
};
