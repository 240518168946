import { Api } from "api";
import { Screenline } from "api/analytics";
import { produce } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, select, takeLatest } from "redux-saga/effects";

import { buildFilters } from "features/filters/utils";
import { getAvailableZoomLevels } from "features/map/utils";

import { FiltersType, MeasureRange, MeasureType, ODMeasureRange, ODTileLayer, QueryType, RoadsMeasure } from "types";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "../interfaces";
import {
  AnalyticsActionType,
  FiltersActionType,
  RoadIntersectionsActionType,
  ScreenlinesActionType,
} from "./actionTypes";
import { selectSelectedScreenline } from "./screenlines";

interface ODFiltersPayload {
  filter: FiltersType | null;
  queryType: QueryType;
}

interface DatasetFiltersPayload {
  filter: FiltersType | null;
  datasetId: string;
  queryType: QueryType;
}

interface RoadFiltersPayload {
  filter: FiltersType | null;
}

export interface FiltersState {
  measure: MeasureType;
  queryType: QueryType;
  availableRangeByZone: MeasureRange | null;
  rangeByZone: [number, number] | null;
  zoningLevel: ODTileLayer | null;
  roadClasses: number[] | null;

  //OD
  ODFilters: FiltersType | null;
  ODAvailableRange: LoadingErrorData<ODMeasureRange>;
  ODRange: { [key: string]: [number, number] } | null;

  //Dataset
  datasetFilters: FiltersType | null;
  datasetAvailableRange: LoadingErrorData<ODMeasureRange>;
  datasetRange: { [key: string]: [number, number] } | null;

  //Roads
  roadFilters: FiltersType | null;
  roadAvailableRange: LoadingErrorData<MeasureRange>;
  roadRange: [number, number] | null;
}

const initialState: FiltersState = {
  measure: MeasureType.AADT,
  queryType: QueryType.INCOMING,
  availableRangeByZone: null,
  rangeByZone: null,
  zoningLevel: null,
  roadClasses: null,

  //OD
  ODFilters: null,
  ODAvailableRange: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODRange: null,

  //Dataset
  datasetFilters: null,
  datasetAvailableRange: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  datasetRange: null,

  //Road
  roadFilters: null,
  roadAvailableRange: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadRange: null,
};

export type FiltersAction = ActionsUnion<typeof filtersActions>;

export const filtersActions = {
  clearFilters: () => createAction(FiltersActionType.CLEAR_FILTERS),

  setMeasure: (measure: MeasureType) => createAction(FiltersActionType.SET_MEASURE, measure),
  setMeasureCommit: (measure: MeasureType) => createAction(FiltersActionType.SET_MEASURE_COMMIT, measure),

  setQueryType: (queryType: QueryType) => createAction(FiltersActionType.SET_QUERY_TYPE, queryType),

  setAvailableRangeByZone: (measureRange: MeasureRange) =>
    createAction(FiltersActionType.SET_AVAILABLE_RANGE_BY_ZONE, measureRange),
  setRangeByZone: (range: [number, number] | null) => createAction(FiltersActionType.SET_RANGE_BY_ZONE, range),

  setZoningLevel: (zoningLevel: ODTileLayer | null) => createAction(FiltersActionType.SET_ZONING_LEVEL, zoningLevel),

  setRoadClasses: (roadClasses: number[] | null) => createAction(FiltersActionType.SET_ROAD_CLASSES, roadClasses),
  updateRoadClassesFromMetadata: (roadClasses: number[]) =>
    createAction(FiltersActionType.UPDATE_ROAD_CLASSES_FROM_METADATA, roadClasses),

  //OD
  setODFilters: (payload: ODFiltersPayload) => createAction(FiltersActionType.SET_OD_FILTERS, payload),
  setODFiltersSucceeded: (filters: FiltersType | null) =>
    createAction(FiltersActionType.SET_OD_FILTERS_SUCCEEDED, filters),
  setODRange: (zoningLevelRange: { [key: string]: [number, number] } | null) =>
    createAction(FiltersActionType.SET_OD_RANGE, zoningLevelRange),

  fetchODAvailableRange: (payload: ODFiltersPayload) =>
    createAction(FiltersActionType.FETCH_OD_AVAILABLE_RANGE, payload),
  fetchODAvailableRangeSucceeded: (measureRange: ODMeasureRange) =>
    createAction(FiltersActionType.FETCH_OD_AVAILABLE_RANGE_SUCCEEDED, measureRange),
  fetchODAvailableRangeFailed: (error: ResponseError) =>
    createAction(FiltersActionType.FETCH_OD_AVAILABLE_RANGE_FAILED, error),

  //Dataset
  setDatasetFilters: (payload: DatasetFiltersPayload) => createAction(FiltersActionType.SET_DATASET_FILTERS, payload),
  setDatasetFiltersSucceeded: (filters: FiltersType | null) =>
    createAction(FiltersActionType.SET_DATASET_FILTERS_SUCCEEDED, filters),
  setDatasetRange: (zoningLevelRange: { [key: string]: [number, number] } | null) =>
    createAction(FiltersActionType.SET_DATASET_RANGE, zoningLevelRange),

  fetchDatasetAvailableRange: (payload: DatasetFiltersPayload) =>
    createAction(FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE, payload),
  fetchDatasetAvailableRangeSucceeded: (measureRange: ODMeasureRange) =>
    createAction(FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE_SUCCEEDED, measureRange),
  fetchDatasetAvailableRangeFailed: (error: ResponseError) =>
    createAction(FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE_FAILED, error),

  //Road
  setRoadFilters: (payload: RoadFiltersPayload) => createAction(FiltersActionType.SET_ROAD_FILTERS, payload),
  setRoadFiltersSucceeded: (filters: FiltersType | null) =>
    createAction(FiltersActionType.SET_ROAD_FILTERS_SUCCEEDED, filters),
  setRoadsRange: (range: [number, number] | null) => createAction(FiltersActionType.SET_ROADS_RANGE, range),

  fetchRoadsAvailableRange: (payload: RoadFiltersPayload) =>
    createAction(FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE, payload),
  fetchRoadsAvailableRangeSucceeded: (measureRange: MeasureRange) =>
    createAction(FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE_SUCCEEDED, measureRange),
  fetchRoadsAvailableRangeFailed: (error: ResponseError) =>
    createAction(FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE_FAILED, error),
};

const reducer: Reducer<FiltersState, FiltersAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FiltersActionType.CLEAR_FILTERS: {
        draft.measure = initialState.measure;
        draft.queryType = initialState.queryType;
        draft.ODFilters = initialState.ODFilters;
        draft.datasetFilters = initialState.datasetFilters;
        draft.roadFilters = initialState.roadFilters;
        draft.roadClasses = initialState.roadClasses;
        draft.ODAvailableRange = initialState.ODAvailableRange;
        draft.ODRange = initialState.ODRange;
        draft.availableRangeByZone = initialState.availableRangeByZone;
        draft.rangeByZone = initialState.rangeByZone;
        draft.datasetAvailableRange = initialState.datasetAvailableRange;
        draft.datasetRange = initialState.datasetRange;
        draft.roadAvailableRange = initialState.roadAvailableRange;
        draft.roadRange = initialState.roadRange;
        draft.zoningLevel = initialState.zoningLevel;
        return;
      }
      case FiltersActionType.SET_MEASURE_COMMIT: {
        draft.measure = action.payload;
        return;
      }
      case FiltersActionType.SET_QUERY_TYPE: {
        draft.queryType = action.payload;
        return;
      }
      case FiltersActionType.SET_AVAILABLE_RANGE_BY_ZONE: {
        draft.availableRangeByZone = action.payload;
        return;
      }
      case FiltersActionType.SET_RANGE_BY_ZONE: {
        draft.rangeByZone = action.payload;
        return;
      }
      case FiltersActionType.SET_ZONING_LEVEL: {
        draft.zoningLevel = action.payload;
        return;
      }
      case FiltersActionType.SET_ROAD_CLASSES: {
        draft.roadClasses = action.payload;
        return;
      }
      case FiltersActionType.UPDATE_ROAD_CLASSES_FROM_METADATA: {
        const roadClasses = action.payload;

        if (!state.roadClasses || state.roadClasses.length === 0) {
          draft.roadClasses = roadClasses;
        } else {
          const newRoadClasses = state.roadClasses.filter((id) => roadClasses?.includes(id));
          draft.roadClasses = newRoadClasses;
        }

        return;
      }

      //OD
      case FiltersActionType.SET_OD_FILTERS_SUCCEEDED: {
        draft.ODFilters = action.payload;

        draft.ODRange = null;
        draft.rangeByZone = null;
        draft.datasetRange = null;
        return;
      }
      case FiltersActionType.SET_OD_RANGE: {
        const newODRange = action.payload;
        draft.ODRange = newODRange
          ? {
              ...state.ODRange,
              ...newODRange,
            }
          : newODRange;
        return;
      }
      case FiltersActionType.FETCH_OD_AVAILABLE_RANGE: {
        draft.ODAvailableRange = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case FiltersActionType.FETCH_OD_AVAILABLE_RANGE_SUCCEEDED: {
        const range = action.payload;
        const zoningLevel = state.zoningLevel?.level;

        if (zoningLevel) {
          const newAvailableMin = range[zoningLevel].min;
          const newAvailableMax = range[zoningLevel].max;
          const previousAvailableMin = state.ODAvailableRange.data?.[zoningLevel].min;
          const previousAvailableMax = state.ODAvailableRange.data?.[zoningLevel].max;
          const currentMinRange = state.ODRange?.[zoningLevel]?.[0] || 0;
          const currentMaxRange = state.ODRange?.[zoningLevel]?.[1] || Infinity;

          const minRange =
            currentMinRange === previousAvailableMin ? newAvailableMin : Math.max(currentMinRange, newAvailableMin);
          const maxRange =
            currentMaxRange === previousAvailableMax ? newAvailableMax : Math.min(currentMaxRange, newAvailableMax);

          draft.ODRange = { [zoningLevel]: [minRange, maxRange] };
        }
        draft.ODAvailableRange = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case FiltersActionType.FETCH_OD_AVAILABLE_RANGE_FAILED: {
        draft.ODAvailableRange = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }

      //Dataset
      case FiltersActionType.SET_DATASET_FILTERS_SUCCEEDED: {
        draft.datasetFilters = action.payload;

        draft.datasetRange = null;
        draft.rangeByZone = null;
        return;
      }
      case FiltersActionType.SET_DATASET_RANGE: {
        const newDatasetRange = action.payload;
        draft.datasetRange = newDatasetRange
          ? {
              ...state.datasetRange,
              ...newDatasetRange,
            }
          : newDatasetRange;
        return;
      }
      case FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE: {
        draft.datasetAvailableRange = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE_SUCCEEDED: {
        draft.datasetAvailableRange = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE_FAILED: {
        draft.datasetAvailableRange = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }

      //Road
      case FiltersActionType.SET_ROAD_FILTERS_SUCCEEDED: {
        draft.roadFilters = action.payload;

        draft.roadRange = null;
        return;
      }
      case FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE: {
        draft.roadAvailableRange = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE_SUCCEEDED: {
        const range = action.payload;
        const newAvailableMin = range.min;
        const newAvailableMax = range.max;
        const previousAvailableMin = state.roadAvailableRange.data?.min;
        const previousAvailableMax = state.roadAvailableRange.data?.max;
        const currentMinRange = state.roadRange?.[0] || 0;
        const currentMaxRange = state.roadRange?.[1] || Infinity;

        const minRange =
          currentMinRange === previousAvailableMin ? newAvailableMin : Math.max(currentMinRange, range.min);
        const maxRange =
          currentMaxRange === previousAvailableMax ? newAvailableMax : Math.min(currentMaxRange, range.max);

        draft.roadRange = [minRange, maxRange];
        draft.roadAvailableRange = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE_FAILED: {
        draft.roadAvailableRange = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case FiltersActionType.SET_ROADS_RANGE: {
        draft.roadRange = action.payload;
        return;
      }

      default:
        return state;
    }
  });

export default reducer;

function* setODFilters(action: Action<string, ODFiltersPayload>): Generator {
  yield put({ type: AnalyticsActionType.CLEAR_OD_COUNTS });
  yield put({ type: AnalyticsActionType.CLEAR_ZONE_COUNTS_BY_ZONE_ID });
  yield put({ type: AnalyticsActionType.CLEAR_ZONE_DETAILS });
  yield put({ type: FiltersActionType.SET_OD_FILTERS_SUCCEEDED, payload: action.payload.filter });
}

function* setDatasetFilters(action: Action<string, DatasetFiltersPayload>): Generator {
  yield put({ type: AnalyticsActionType.CLEAR_DATASET_COUNTS });
  yield put({ type: AnalyticsActionType.CLEAR_DATASET_COUNTS_BY_ZONE_ID });
  yield put({ type: AnalyticsActionType.CLEAR_ZONE_DETAILS });
  yield put({ type: FiltersActionType.SET_DATASET_FILTERS_SUCCEEDED, payload: action.payload.filter });
}

function* setRoadFilters(action: Action<string, RoadFiltersPayload>): Generator {
  yield put({ type: AnalyticsActionType.CLEAR_ROADS_VOLUMES });
  yield put({ type: RoadIntersectionsActionType.CLEAR_ROAD_INTERSECTION_VOLUMES });
  yield put({ type: FiltersActionType.SET_ROAD_FILTERS_SUCCEEDED, payload: action.payload.filter });
}

function* fetchODMeasureRange(action: Action<string, ODFiltersPayload>): Generator {
  try {
    const { filter, queryType } = action.payload;
    const ODMetadata: any = yield select((state) => state.analytics.ODMetadata);
    const selectedFocusArea: any = yield select((state) => state.global.selectedFocusArea);
    const timePeriod: any = yield select((state) => state.global.timePeriod);
    const measure: any = yield select((state) => state.filters.measure);

    const levels = getAvailableZoomLevels(ODMetadata.data?.tileService.layers);
    const config = {
      filter: buildFilters(filter),
      timePeriod,
      measure,
      queryType,
      areaOfInterest: selectedFocusArea?.areas || null,
    };

    const api = yield getContext("api");
    const {
      analyticsApi: { getODMeasureRange },
    } = api as Api;
    const measureRange = yield call(getODMeasureRange, levels, config);
    yield put({
      type: FiltersActionType.FETCH_OD_AVAILABLE_RANGE_SUCCEEDED,
      payload: measureRange,
    });
  } catch (e: any) {
    yield put({
      type: FiltersActionType.FETCH_OD_AVAILABLE_RANGE_FAILED,
      error: {
        message: e.message,
      },
    });
  }
}

function* fetchDatasetMeasureRange(action: Action<string, DatasetFiltersPayload>): Generator {
  try {
    const { filter, datasetId, queryType } = action.payload;
    const datasetMetadata: any = yield select((state) => state.analytics.datasetMetadata);
    const selectedFocusArea: any = yield select((state) => state.global.selectedFocusArea);
    const timePeriod: any = yield select((state) => state.global.timePeriod);
    const measure: any = yield select((state) => state.filters.measure);

    const levels = getAvailableZoomLevels(datasetMetadata.data?.tileService.layers);
    const config = {
      filter: buildFilters(filter),
      timePeriod,
      measure,
      queryType,
      areaOfInterest: selectedFocusArea?.areas || null,
    };

    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetMeasureRange },
    } = api as Api;
    const measureRange = yield call(getDatasetMeasureRange, datasetId, levels, config);
    yield put({
      type: FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE_SUCCEEDED,
      payload: measureRange,
    });
  } catch (e: any) {
    yield put({
      type: FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE_FAILED,
      error: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadsMeasureRange(action: Action<string, RoadFiltersPayload>): Generator {
  try {
    const { filter } = action.payload;
    const selectedFocusArea: any = yield select((state) => state.global.selectedFocusArea);
    const timePeriod: any = yield select((state) => state.global.timePeriod);
    const measure: any = yield select((state) => state.filters.measure);

    const config = {
      filter: buildFilters(filter),
      timePeriod,
      measure,
      datasetId: selectedFocusArea?.datasetId,
    };

    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadsMeasureRange },
    } = api as Api;
    const measureRange = yield call(getRoadsMeasureRange, config);
    yield put({
      type: FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE_SUCCEEDED,
      payload: measureRange,
    });
  } catch (e: any) {
    yield put({
      type: FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE_FAILED,
      error: {
        message: e.message,
      },
    });
  }
}

function* setMeasure(action: Action<string, MeasureType>): Generator {
  const selectedScreenline = (yield select(selectSelectedScreenline)) as Screenline;
  const filterByRoadNetworkType = (yield select((state) => state.screenlines.filterByRoadNetworkType)) as boolean;
  const measures = (yield select((state) => state.analytics.roadsMetadata.data?.measures)) as RoadsMeasure[];
  const roadNetwork = measures?.find((m: RoadsMeasure) => m.columnName === action.payload)?.network;

  if (selectedScreenline && filterByRoadNetworkType && roadNetwork !== selectedScreenline.network) {
    yield put({ type: ScreenlinesActionType.SET_SELECTED_SCREENLINE_ID, payload: null });
  }

  yield put({ type: FiltersActionType.SET_MEASURE_COMMIT, payload: action.payload });
}

export function* filtersSaga() {
  yield all([
    takeLatest(FiltersActionType.SET_OD_FILTERS, setODFilters),
    takeLatest(FiltersActionType.SET_DATASET_FILTERS, setDatasetFilters),
    takeLatest(FiltersActionType.SET_ROAD_FILTERS, setRoadFilters),
    takeLatest(FiltersActionType.FETCH_OD_AVAILABLE_RANGE, fetchODMeasureRange),
    takeLatest(FiltersActionType.FETCH_DATASET_AVAILABLE_RANGE, fetchDatasetMeasureRange),
    takeLatest(FiltersActionType.FETCH_ROADS_AVAILABLE_RANGE, fetchRoadsMeasureRange),
    takeLatest(FiltersActionType.SET_MEASURE, setMeasure),
  ]);
}
