import { useAuth0 } from "@auth0/auth0-react";
import { Box, MenuItem, Stack, styled } from "@mui/material";
import {
  Divider,
  FiltersAndLayersContainer,
  MapControlContainer,
  SliderControl,
  TextField,
  VisibilityIconButton,
} from "components_new";
import { Map } from "mapbox-gl";
import React, { ChangeEvent, FC, MutableRefObject, memo, useEffect, useMemo, useState } from "react";

import { AreaName, FocusAreaDropdown, LeftSidebar } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { corridorActions } from "store/sections/corridor";
import { globalActions } from "store/sections/global";

import { MapLayerContainerId } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { calculateWidthFactor, valueLabelFormat } from "../map/utils";
import { Filters } from "./Filters";
import { RangeFilter } from "./RangeFilter";
import {
  CORRIDOR_EDGE_LAYER_ID,
  CORRIDOR_NODE_LAYER_ID,
  getEdgesOffsetExpression,
  getEdgesOpacityExpression,
  getEdgesWidthExpression,
  getHeatmapIntensityExpression,
  getHeatmapOpacityExpression,
  getHeatmapRadiusExpression,
  layerIdsEndings,
} from "./map-data/corridor/layers";

interface MapControlsPanelProps {
  mapController: MutableRefObject<null | any>;
  map: Map | null;
}

const AreaNameContainer = styled("div")`
  margin: 1rem 0;
  display: flex;
`;

function calculateHeatmapIntensityFactor(value: number) {
  return value ** 2;
}

function calculateHeatmapRadiusFactor(value: number) {
  return value ** 2;
}

export const MapControlsPanel: FC<MapControlsPanelProps> = memo(({ mapController, map }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  const savedEdgesOpacityFactor = useAppSelector((state) => state.corridor.edgesOpacityFactor);
  const savedEdgesWidthFactor = useAppSelector((state) => state.corridor.edgesWidthFactor);
  const savedHeatmapOpacityFactor = useAppSelector((state) => state.corridor.heatmapOpacityFactor);
  const savedHeatmapIntensityFactor = useAppSelector((state) => state.corridor.heatmapIntensityFactor);
  const savedHeatmapRadiusFactor = useAppSelector((state) => state.corridor.heatmapRadiusFactor);
  const corridorEdgesVisible = useAppSelector((state) => state.corridor.showEdges);
  const heatmapVisible = useAppSelector((state) => state.corridor.showHeatmap);

  const collapsedMapLayerContainers = useAppSelector((state) => state.global.collapsedMapLayerContainers);

  const [edgesWidthFactor, setEdgeWidthFactor] = useState(savedEdgesWidthFactor);
  const [heatmapIntensityFactor, setHeatmapIntensityFactor] = useState(savedHeatmapIntensityFactor);
  const [heatmapRadiusFactor, setHeatmapRadiusFactor] = useState(savedHeatmapRadiusFactor);
  const [heatmapOpacityFactor, setHeatmapOpacityFactor] = useState(savedHeatmapOpacityFactor);
  const [edgesOpacityFactor, setEdgesOpacityFactor] = useState(savedEdgesOpacityFactor);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const permissions = useAppSelector((state) => state.license.permissions);

  const corridorMetadata = useAppSelector((state) => state.corridor.corridorMetadata);
  const corridorEdgeIds = useAppSelector((state) => state.corridor.corridorEdgeIds);
  const corridorEdgeCounts = useAppSelector((state) => state.corridor.corridorEdgeCounts);
  const corridorNodeIds = useAppSelector((state) => state.corridor.corridorNodeIds);
  const corridorNodeCounts = useAppSelector((state) => state.corridor.corridorNodeCounts);
  const corridorHeatmapConfiguration = useAppSelector((state) => state.corridor.corridorHeatmapConfiguration);
  const serviceOverlayLayers = useAppSelector((state) => state.corridor.serviceLayers);

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const filterLoading = useMemo(() => {
    return (
      corridorMetadata.state === DataState.LOADING ||
      corridorEdgeIds.state === DataState.LOADING ||
      corridorEdgeCounts.state === DataState.LOADING ||
      corridorNodeIds.state === DataState.LOADING ||
      corridorNodeCounts.state === DataState.LOADING ||
      corridorHeatmapConfiguration.state === DataState.LOADING ||
      serviceOverlayLayers.state === DataState.LOADING
    );
  }, [
    corridorMetadata.state,
    corridorEdgeIds.state,
    corridorEdgeCounts.state,
    corridorNodeIds.state,
    corridorNodeCounts.state,
    corridorHeatmapConfiguration.state,
    serviceOverlayLayers.state,
  ]);

  const selectedFocusArea = useMemo(
    () => focusAreas.data?.find((area) => area.id === selectedFocusAreaId) || null,
    [focusAreas.data, selectedFocusAreaId],
  );

  const isDataset = useMemo(() => Boolean(selectedFocusArea?.datasetId), [selectedFocusArea?.datasetId]);

  const handleToggleEdgesVisibility = () => {
    if (mapController.current) {
      const areCorridorEdgesVisible = !corridorEdgesVisible;
      dispatch(corridorActions.setShowEdges(areCorridorEdgesVisible));

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        layerIdsEndings.forEach((ending) => {
          mapController.current.layerManager.updateLayerLayout(
            `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}${ending}`,
            "visibility",
            areCorridorEdgesVisible ? "visible" : "none",
          );
        });
      });

      addCustomGAEvent(
        "corridor",
        "edges",
        areCorridorEdgesVisible ? "visible" : "invisible",
        user,
        userOrganizationName,
      );
    }
  };

  const handleChangeCorridorEdgeWidth = (widthFactor: number) => {
    dispatch(corridorActions.setEdgesWidthFactor(widthFactor));

    Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
      mapController.current.layerManager.updateLayerPaint(
        `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
        "line-width",
        getEdgesWidthExpression(widthFactor),
      );

      mapController.current.layerManager.updateLayerPaint(
        `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
        "line-offset",
        getEdgesOffsetExpression(widthFactor),
      );

      mapController.current.layerManager.updateLayerPaint(
        `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_volume`,
        "line-width",
        getEdgesWidthExpression(widthFactor),
      );

      mapController.current.layerManager.updateLayerPaint(
        `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_volume`,
        "line-offset",
        getEdgesOffsetExpression(widthFactor),
      );
    });

    addCustomGAEvent("corridor", "edges", "change_width", user, userOrganizationName);
  };

  const handleChangeEdgesOpacity = (opacityFactor: number) => {
    dispatch(corridorActions.setEdgesOpacityFactor(opacityFactor));

    Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
      if (map?.getLayer(`${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`)) {
        mapController.current?.layerManager?.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
          "line-opacity",
          getEdgesOpacityExpression(opacityFactor),
        );
      }
    });
  };

  const handleToggleHeatMapVisibility = () => {
    if (mapController.current) {
      const isHeatmapVisible = !heatmapVisible;
      dispatch(corridorActions.setShowHeatmap(isHeatmapVisible));

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerLayout(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "visibility",
          isHeatmapVisible ? "visible" : "none",
        );
      });

      addCustomGAEvent("corridor", "heatmap", isHeatmapVisible ? "visible" : "invisible", user, userOrganizationName);
    }
  };

  const handleChangeHeatmapIntensity = (heatmapIntensity: number) => {
    dispatch(corridorActions.setHeatmapIntensityFactor(heatmapIntensity));

    Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
      mapController.current.layerManager.updateLayerPaint(
        `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
        "heatmap-intensity",
        getHeatmapIntensityExpression(heatmapIntensity, corridorHeatmapConfiguration.data),
      );
    });

    addCustomGAEvent("corridor", "heatmap", "change_intensity", user, userOrganizationName);
  };

  const handleChangeHeatmapRadius = (heatmapRadius: number) => {
    dispatch(corridorActions.setHeatmapRadiusFactor(heatmapRadius));

    Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
      mapController.current.layerManager.updateLayerPaint(
        `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
        "heatmap-radius",
        getHeatmapRadiusExpression(heatmapRadius, corridorHeatmapConfiguration.data),
      );
    });

    addCustomGAEvent("corridor", "heatmap", "change_radius", user, userOrganizationName);
  };

  const handleChangeHeatmapOpacity = (opacityFactor: number) => {
    dispatch(corridorActions.setHeatmapOpacityFactor(opacityFactor));

    Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
      if (map?.getLayer(`${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`)) {
        mapController.current.layerManager?.updateLayerPaint(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "heatmap-opacity",
          getHeatmapOpacityExpression(opacityFactor),
        );
      }
    });
  };

  const handleChangeFocusArea = (focusAreaId: string) => {
    if (focusAreaId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId }));
      dispatch(corridorActions.clearServiceOverlayLayers());

      addCustomGAEvent("corridor", "aoi", "change", user, userOrganizationName);
    }
  };

  const handleChangeTimePeriod = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(globalActions.setTimePeriod(event.target.value));

    addCustomGAEvent("corridor", "time_period", "change", user, userOrganizationName);
  };

  useEffect(() => {
    return () => {
      dispatch(corridorActions.clearCorridorMetadata());
      dispatch(corridorActions.updateCurrentFilters(null));
      dispatch(corridorActions.clearServiceOverlayLayers());
    };
  }, [dispatch]);

  return (
    <LeftSidebar>
      <Box
        sx={{
          height: "100%",
          display: "grid",
          rowGap: 2,
          gridTemplateRows: "auto minmax(100px, 1fr)",
        }}
      >
        <Stack spacing={2}>
          <div>
            <FocusAreaDropdown
              loading={focusAreas.state === DataState.LOADING}
              disabled={focusAreas.state === DataState.EMPTY || focusAreas.state === DataState.ERROR}
              options={
                focusAreas.data?.filter(
                  (area) =>
                    !area.datasetId &&
                    permissions.data?.licensedAreas.find(
                      (a) => a.licensedAreaId.toString() === area.licensedAreaId && a.dataDetail.corridorDetail,
                    ),
                ) || []
              }
              value={selectedFocusArea}
              onChange={handleChangeFocusArea}
            />
            {isDataset && (
              <AreaNameContainer>
                <AreaName>{selectedFocusArea?.region}</AreaName>
              </AreaNameContainer>
            )}
          </div>
          <TextField
            fullWidth
            select
            value={timePeriod || ""}
            label="Time Period"
            disabled={(selectedFocusArea?.timePeriods?.length ?? 1) <= 1}
            onChange={handleChangeTimePeriod}
          >
            {selectedFocusArea?.timePeriods?.map((timePeriod) => (
              <MenuItem key={timePeriod} value={timePeriod}>
                {timePeriod}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <FiltersAndLayersContainer>
          <Filters loading={filterLoading} />

          <MapControlContainer
            title="Corridor edges"
            primaryAction={
              <VisibilityIconButton visible={corridorEdgesVisible} onClick={handleToggleEdgesVisibility} />
            }
            collapse
            expanded={!collapsedMapLayerContainers.includes(MapLayerContainerId.CORRIDOR_EDGES)}
            onChange={() =>
              dispatch(globalActions.toggleLayerContainerCollapsedState(MapLayerContainerId.CORRIDOR_EDGES))
            }
          >
            <Box padding={1}>
              <RangeFilter label="Filter" mapController={mapController} map={map} />
              <Divider sx={{ marginY: 0.5 }} />
              <SliderControl
                label="Width"
                loading={filterLoading}
                value={edgesWidthFactor}
                defaultValue={1}
                min={0}
                max={2}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                scale={calculateWidthFactor}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={(e, value) => setEdgeWidthFactor(value as number)}
                onChangeCommitted={(e, value) => handleChangeCorridorEdgeWidth(value as number)}
              />
              <SliderControl
                label="Opacity"
                loading={filterLoading}
                value={edgesOpacityFactor}
                defaultValue={1}
                min={0}
                max={1}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                valueLabelDisplay="auto"
                onChange={(e, value) => setEdgesOpacityFactor(value as number)}
                onChangeCommitted={(e, value) => handleChangeEdgesOpacity(value as number)}
              />
            </Box>
          </MapControlContainer>
          <MapControlContainer
            title="Heatmap"
            primaryAction={<VisibilityIconButton visible={heatmapVisible} onClick={handleToggleHeatMapVisibility} />}
            collapse
            expanded={!collapsedMapLayerContainers.includes(MapLayerContainerId.CORRIDOR_HEATMAP)}
            onChange={() =>
              dispatch(globalActions.toggleLayerContainerCollapsedState(MapLayerContainerId.CORRIDOR_HEATMAP))
            }
          >
            <Box padding={1}>
              <SliderControl
                label="Intensity"
                loading={filterLoading}
                value={heatmapIntensityFactor}
                defaultValue={1}
                min={0}
                max={2}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                scale={calculateHeatmapIntensityFactor}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={(e, value) => setHeatmapIntensityFactor(value as number)}
                onChangeCommitted={(e, value) => handleChangeHeatmapIntensity(value as number)}
              />
              <SliderControl
                label="Radius"
                loading={filterLoading}
                value={heatmapRadiusFactor}
                defaultValue={1}
                min={0}
                max={2}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                scale={calculateHeatmapRadiusFactor}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={(e, value) => setHeatmapRadiusFactor(value as number)}
                onChangeCommitted={(e, value) => handleChangeHeatmapRadius(value as number)}
              />
              <SliderControl
                label="Opacity"
                loading={filterLoading}
                value={heatmapOpacityFactor}
                defaultValue={1}
                min={0}
                max={1}
                step={0.1}
                marks={[
                  {
                    value: 1,
                    label: "",
                  },
                ]}
                valueLabelDisplay="auto"
                onChange={(e, value) => setHeatmapOpacityFactor(value as number)}
                onChangeCommitted={(e, value) => handleChangeHeatmapOpacity(value as number)}
              />
            </Box>
          </MapControlContainer>
        </FiltersAndLayersContainer>
      </Box>
    </LeftSidebar>
  );
});
