import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import background from "assets/svg/dashboard/image.svg";
import React, { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { FeatureHighlightItem, FeatureHighlightItemInfo } from "features/dashboard/FeatureHighlight";
import { FocusAreaPreview } from "features/dashboard/FocusAreaPreview";
import { LearnMoreCard } from "features/dashboard/LearnMoreCard";
import { MeetUsEvent } from "features/dashboard/MeetUsEvent";
import { ReleaseInfo } from "features/dashboard/ReleaseInfo";

import { HighligthPageContainer } from "components/atoms/page-container/HighlightPageContainer";
import { PageContainerPanel, StandardTitle } from "components/atoms/page-container/PageContainerPanel";
import Carousel from "components/molecules/carousel/Carrousel";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import {
  selectAtLeastOnePedestriansMeasure,
  selectAtLeastOneRoadVmtDataPermission,
  selectAtLeastOneScreenlinePermission,
  selectPedestriansLicensedAreaId,
} from "store/permissionsSelectors";
import { analyticsActions } from "store/sections/analytics";
import { filtersActions } from "store/sections/filters";
import { globalActions } from "store/sections/global";
import { mapActions } from "store/sections/map";
import { screenlinesActions } from "store/sections/screenlines";

import { MapVisualizationType, MeasureType } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import {
  PEDESTRIANS_SLIDE_NAME,
  SCREENLINE_SLIDE_NAME,
  UI_IMPROVEMENTS_SLIDE_NAME,
  getFeatureHighlightsPanel,
  learnMorePanel,
  meetUsPanel,
} from "./DashboardConfigs";

const DashboardPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 1fr 460px;
  grid-auto-rows: min-content;
  gap: 2rem;
`;

const LearnMoreContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  gap: 1rem;
`;

const EventsAndReleasesContainer = styled(Box)`
  display: grid;
  row-gap: 1rem;
`;

export const DashboardPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const isRoadVmtAllowed = useAppSelector(selectAtLeastOneRoadVmtDataPermission);
  const isScreenlineAllowed = useAppSelector(selectAtLeastOneScreenlinePermission);
  const isPedestriansAllowed = useAppSelector(selectAtLeastOnePedestriansMeasure);
  const pedestriansLicensedAreaId = useAppSelector(selectPedestriansLicensedAreaId);

  const featureHighlights = useMemo(
    () => getFeatureHighlightsPanel(isRoadVmtAllowed, isScreenlineAllowed, isPedestriansAllowed),
    [isRoadVmtAllowed, isScreenlineAllowed, isPedestriansAllowed],
  );

  const dashboardTriggerGAEvent = (event: string) => {
    addCustomGAEvent("dashboard", "click", event, user, userOrganizationName);
  };

  const tryNewFeatureCallback = (item: FeatureHighlightItemInfo) => {
    if (item.event === UI_IMPROVEMENTS_SLIDE_NAME) {
      dispatch(analyticsActions.setMapVisualizationMode(MapVisualizationType.ROADS));
      navigate(item.buttonUrl);
    } else if (item.event === PEDESTRIANS_SLIDE_NAME && pedestriansLicensedAreaId) {
      dispatch(mapActions.clearMapBounds());
      dispatch(globalActions.setTimePeriod("2023"));
      dispatch(
        globalActions.setSelectedFocusAreaId({
          focusAreaId: pedestriansLicensedAreaId,
          reset: false,
        }),
      );
      dispatch(analyticsActions.setMapVisualizationMode(MapVisualizationType.ROADS));
      dispatch(filtersActions.setMeasure(MeasureType.PEDESTRIANS));
      navigate(item.buttonUrl);
    } else if (item.event === SCREENLINE_SLIDE_NAME) {
      dispatch(analyticsActions.setMapVisualizationMode(MapVisualizationType.ROADS));
      dispatch(screenlinesActions.setScreelineEditorOpen(true));
      navigate(item.buttonUrl);
    } else if (item.buttonUrl) {
      navigate(item.buttonUrl);
    }
  };

  usePageTracking();

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(analyticsActions.fetchFocusAreasAndDatasets());
    }
  }, [isTokenLoaded, dispatch]);

  return (
    <HighligthPageContainer>
      <DashboardPageContainer>
        <PageContainerPanel title={featureHighlights.title} titleColor="white" backgroundImage={`url(${background})`}>
          <Carousel>
            {featureHighlights.carousel
              .filter((item) => item.enabled)
              .map((item, index) => (
                <FeatureHighlightItem
                  key={`carousel-slide-${index}`}
                  item={item}
                  triggerGAEvent={dashboardTriggerGAEvent}
                  tryNewFeatureCallback={tryNewFeatureCallback}
                />
              ))}
          </Carousel>
        </PageContainerPanel>

        <PageContainerPanel title="Focus area" titleColor="white" compact={true}>
          <FocusAreaPreview triggerGAEvent={dashboardTriggerGAEvent} />
        </PageContainerPanel>

        <PageContainerPanel title={learnMorePanel.title} compact={true}>
          <LearnMoreContainer>
            {learnMorePanel.cards.map((item, index) => (
              <LearnMoreCard key={`learn-more-${index}`} item={item} triggerGAEvent={dashboardTriggerGAEvent} />
            ))}
          </LearnMoreContainer>
        </PageContainerPanel>

        <PageContainerPanel compact={true} noRoundCorners={true}>
          <StandardTitle>Events</StandardTitle>
          <EventsAndReleasesContainer>
            {meetUsPanel.events.map((item, idx) => (
              <MeetUsEvent key={`events-${idx}`} item={item} triggerGAEvent={dashboardTriggerGAEvent}></MeetUsEvent>
            ))}
          </EventsAndReleasesContainer>
          <ReleaseInfo triggerGAEvent={dashboardTriggerGAEvent} />
        </PageContainerPanel>
      </DashboardPageContainer>
    </HighligthPageContainer>
  );
};
