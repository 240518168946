import {
  Check,
  Clear,
  DateRange,
  Deselect,
  InfoOutlined,
  InsertDriveFile,
  Place,
  SelectAll,
} from "@mui/icons-material";
import { Box, Grid, Stack, Tooltip, Typography, styled } from "@mui/material";
import {
  Badge,
  Button,
  CircularProgress,
  ConfirmDialog,
  DialogContentText,
  Divider,
  IconButton,
  MapControlContainer,
  Popover,
  TextField,
  ToggleButton,
  VisibilityIconButton,
} from "components_new";
import { DrawPolygon, MergeDifference, MergeUnion } from "components_new/icons";
import { FormikHelpers, useFormik } from "formik";
import { isEqual } from "lodash";
import React, { Dispatch, FC, SetStateAction, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { SaveErrorDialog } from "features/dataset-editor";

import { AreaName, FlexContainer, RoadClassSelector } from "components";

import { useAppDispatch, useAppSelector, usePrompt } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { DatasetEditorMode, FocusAreaItem, RoadClass } from "types";

import { ZoningSelector } from "./ZoningSelector";
import { DrawIconButton } from "./drawingTools";

const LoaderContainer = styled(FlexContainer)`
  height: 100%;
  justify-content: center;
`;

const FormWrapper = styled("form")`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FieldsContainer = styled(Stack)`
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
  margin-right: -1rem;
`;

const DatasetName = styled("div")`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  & .bp4-editable-text-content,
  .bp4-editable-text-input {
    width: 210px !important;
    padding-right: 0.5rem;
  }
`;

const AreaNameContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export enum SubareaDrawAction {
  UNION = "UNION",
  DIFFERENCE = "DIFFERENCE",
  VERTEX = "VERTEX",
}

export interface DatasetForm {
  datasetName: string;
  description: string;
}

export interface MainPanelProps {
  draw: any;
  zoningOptions: string[];
  selectedZoning: string | undefined;
  showZones: boolean;
  showRoads: boolean;
  showGates: boolean;
  selectedRoadClasses: RoadClass[] | null;
  datasetLicensedArea: FocusAreaItem;
  isGateEditorOpen: boolean;
  editorDrawMode: DatasetEditorMode;
  setEditorDrawMode: Dispatch<SetStateAction<DatasetEditorMode>>;
  setSelectedZoning: (zoning: string, customZoningId?: string) => void;
  setIsGateEditorOpen: (isOpen: boolean) => void;
  changeShowZones: () => void;
  changeShowRoads: () => void;
  changeShowGates: () => void;
  setSelectedRoadClasses: Dispatch<SetStateAction<RoadClass[] | null>>;
  onAddFullEntireAreaPolygon: () => void;
  openValidationModal: () => void;
  handleDeleteSubarea: () => void;
}

export const MainPanel: FC<MainPanelProps> = memo(
  ({
    draw,
    zoningOptions,
    selectedZoning,
    showZones,
    showRoads,
    showGates,
    selectedRoadClasses,
    datasetLicensedArea,
    isGateEditorOpen,
    editorDrawMode,
    setEditorDrawMode,
    setSelectedZoning,
    setIsGateEditorOpen,
    changeShowZones,
    changeShowRoads,
    changeShowGates,
    setSelectedRoadClasses,
    onAddFullEntireAreaPolygon,
    openValidationModal,
    handleDeleteSubarea,
  }) => {
    const dispatch = useAppDispatch();
    const { datasetId } = useParams();

    const [isUnsavedChangesBeforeCompute, setIsUnsavedChangesBeforeCompute] = useState(false);
    const [isNeedToOpenComputeModalAfterSaving, setIsNeedToOpenComputeModalAfterSaving] = useState(false);
    const [isSaveErrorModalOpen, setIsSaveErrorModalOpen] = useState(false);

    const roadClasses = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadClasses);
    const subareaState = useAppSelector((state) => state.analytics.subareaState);
    const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
    const initialODDatasetConfiguration = useAppSelector((state) => state.analytics.savedODDatasetConfig);
    const ODDatasetConfigValidation = useAppSelector((state) => state.analytics.ODDatasetConfigValidation);
    const customZoningId = useAppSelector((state) => state.analytics.ODDatasetConfig.data?.customZoningId);
    const timePeriod = useAppSelector((state) => state.global.timePeriod);
    const loadingSubAreaPolygon = useAppSelector((state) => state.analytics.loadingSubareaPolygon);
    const loadingGenerateGates = useAppSelector((state) => state.analytics.loadingGeneratedGates);
    const addGateState = useAppSelector((state) => state.analytics.newGate.state);
    const newODDatasetConfiguration = useAppSelector((state) => state.analytics.newODDatasetConfig);

    useEffect(() => {
      setIsSaveErrorModalOpen(Boolean(ODDatasetConfiguration.error));
    }, [ODDatasetConfiguration.error]);

    useEffect(() => {
      if (ODDatasetConfiguration.state === DataState.AVAILABLE && isNeedToOpenComputeModalAfterSaving) {
        setIsNeedToOpenComputeModalAfterSaving(false);
        openValidationModal();
      }
    }, [ODDatasetConfiguration.state, isNeedToOpenComputeModalAfterSaving, openValidationModal]);

    const handleSubmit = (
      values: DatasetForm,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
      overwrite?: boolean,
    ) => {
      if (ODDatasetConfiguration.data && selectedZoning) {
        const { gates, subAreaGeometry, timePeriod, gateRoadClasses, licensedAreaId } = ODDatasetConfiguration.data;
        dispatch(
          analyticsActions.updateODDatasetConfig(ODDatasetConfiguration.data.datasetId, {
            datasetName: values.datasetName,
            gates,
            subAreaGeometry,
            description: values.description,
            timePeriod,
            gateRoadClasses,
            licensedAreaId,
            zoningLevel: selectedZoning,
            customZoningId,
            ...(!overwrite && {
              expectedVersion: ODDatasetConfiguration.data.version,
            }),
          }),
        );
        setSubmitting(false);
      }
    };

    const formik = useFormik<DatasetForm>({
      enableReinitialize: true,
      initialValues: {
        datasetName: ODDatasetConfiguration.data?.datasetName || "",
        description: ODDatasetConfiguration.data?.description || "",
      },
      onSubmit: handleSubmit,
    });

    const { values, initialValues, errors, isSubmitting, touched, setFieldValue, setSubmitting, handleReset } = formik;

    const areChangesPending = useMemo(() => {
      if (ODDatasetConfiguration.data && initialValues.datasetName && !newODDatasetConfiguration) {
        const currentODConfiguration = {
          ...ODDatasetConfiguration.data,
          datasetName: values.datasetName,
          description: values.description,
          zoningLevel: selectedZoning,
          ...(customZoningId ? { customZoningId } : {}),
        };
        return !isEqual(initialODDatasetConfiguration, currentODConfiguration);
      }
      return false;
    }, [
      ODDatasetConfiguration.data,
      initialODDatasetConfiguration,
      selectedZoning,
      customZoningId,
      values.datasetName,
      values.description,
      initialValues.datasetName,
      newODDatasetConfiguration,
    ]);

    usePrompt("Changes that you made may not be saved.", areChangesPending);

    const handleSaveAsNewDataset = (datasetName: string, description: string) => {
      if (ODDatasetConfiguration.data) {
        const {
          datasetConfigId,
          timePeriod,
          folderId,
          areaIds,
          zoningLevel,
          customZoningId,
          gateRoadClasses,
          gates,
          subAreaGeometry,
        } = ODDatasetConfiguration.data;

        dispatch(
          analyticsActions.saveAsODDatasetConfig({
            datasetConfigId,
            timePeriod,
            folderId,
            areaIds,
            datasetName,
            description,
            zoningLevel,
            customZoningId,
            gateRoadClasses,
            gates,
            subAreaGeometry,
          }),
        );
      }
    };

    const handleRevertChanges = useCallback(
      (resetForm: (e: any) => void) => {
        if (initialODDatasetConfiguration) {
          resetForm({});

          // Resetting road classes to initial configuration state
          const initialRoadClasses =
            roadClasses?.filter((roadClass) => initialODDatasetConfiguration.gateRoadClasses.includes(roadClass.id)) ||
            [];
          setSelectedRoadClasses((prevRoadClasses) => (prevRoadClasses ? initialRoadClasses : []));

          // Resetting feature in draw to initial configuration state
          draw.deleteAll();
          draw.add(initialODDatasetConfiguration.subAreaGeometry);

          // Resetting configuration in redux
          dispatch(analyticsActions.updateODDatasetConfigSucceeded(initialODDatasetConfiguration));
        }
      },
      [draw, initialODDatasetConfiguration, roadClasses, setSelectedRoadClasses, dispatch],
    );

    const handleDiscardChanges = useCallback(() => {
      if (datasetId) {
        dispatch(analyticsActions.fetchODDatasetConfig(datasetId));
      }
    }, [datasetId, dispatch]);

    const handleComputeSubmit = () => {
      if (areChangesPending) {
        setIsUnsavedChangesBeforeCompute(true);
      } else {
        openValidationModal();
      }
    };

    const handleCloseConfirmSaveBeforeCompute = useCallback(() => {
      if (isUnsavedChangesBeforeCompute) {
        setIsUnsavedChangesBeforeCompute(false);
      }
    }, [isUnsavedChangesBeforeCompute]);

    const handleConfirmSaveBeforeComputeSubmit = (values: DatasetForm, formikHelpers: FormikHelpers<DatasetForm>) => {
      handleSubmit(values, formikHelpers);
      setIsUnsavedChangesBeforeCompute(false);
      setIsNeedToOpenComputeModalAfterSaving(true);
    };

    const toggleUnionDrawMode = () => {
      if (editorDrawMode === DatasetEditorMode.MergeUnion) {
        draw.changeMode("simple_select");
        setEditorDrawMode(DatasetEditorMode.SimpleSelect);
      } else {
        draw.changeMode("draw_polygon");
        setEditorDrawMode(DatasetEditorMode.MergeUnion);
      }
    };

    const toggleDifferenceDrawMode = () => {
      if (editorDrawMode === DatasetEditorMode.MergeDifference) {
        draw.changeMode("simple_select");
        setEditorDrawMode(DatasetEditorMode.SimpleSelect);
      } else {
        draw.changeMode("draw_polygon");
        setEditorDrawMode(DatasetEditorMode.MergeDifference);
      }
    };

    const loading =
      isSubmitting ||
      loadingSubAreaPolygon ||
      loadingGenerateGates ||
      addGateState === DataState.LOADING ||
      ODDatasetConfiguration.state === DataState.LOADING ||
      subareaState.state === DataState.LOADING ||
      ODDatasetConfigValidation.state === DataState.LOADING;

    const isEditingGate =
      editorDrawMode === DatasetEditorMode.DrawGate || editorDrawMode === DatasetEditorMode.AddSegmentsToGate;

    return (
      <>
        {isSaveErrorModalOpen && (
          <SaveErrorDialog
            error={ODDatasetConfiguration.error as any}
            open={isSaveErrorModalOpen}
            handleDiscardChanges={handleDiscardChanges}
            handleOverwrite={() => handleSubmit(values, { setSubmitting }, true)}
            handleSaveAsNewDataset={(datasetName: string) => handleSaveAsNewDataset(datasetName, values.description)}
            onClose={() => setIsSaveErrorModalOpen(false)}
          />
        )}

        {ODDatasetConfiguration.state === DataState.LOADING ? (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        ) : (
          <FormWrapper onSubmit={formik.handleSubmit}>
            <FieldsContainer spacing={2}>
              <DatasetName>
                <InsertDriveFile color="secondary" fontSize="small" sx={{ marginRight: 0.5 }} />
                {values.datasetName}
                {errors.datasetName && <div className="error">{errors.datasetName}</div>}
              </DatasetName>
              <AreaNameContainer>
                <Place color="secondary" fontSize="small" sx={{ marginRight: 0.5 }} />
                <AreaName>{datasetLicensedArea?.region}</AreaName>
              </AreaNameContainer>
              <AreaNameContainer>
                <DateRange color="secondary" fontSize="small" sx={{ marginRight: 0.5 }} />
                <Typography variant="body2" fontWeight={500} color={"text.secondary"}>
                  {timePeriod}
                </Typography>
              </AreaNameContainer>

              <TextField
                fullWidth
                multiline
                placeholder="Dataset description..."
                rows={3}
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />

              <Divider />

              <MapControlContainer
                title="Dataset Subarea"
                secondaryAction={
                  <Popover
                    hover
                    control={(handleOpen, handleClose, open) => (
                      <IconButton
                        size="small"
                        color="secondary"
                        onMouseEnter={handleOpen}
                        onMouseLeave={handleClose}
                        sx={{ marginRight: 0.5 }}
                      >
                        <InfoOutlined fontSize="inherit" />
                      </IconButton>
                    )}
                  >
                    <Box padding={2} maxWidth={600}>
                      <Stack spacing={2}>
                        <div>
                          <Grid container alignItems={"center"}>
                            <DrawPolygon fontSize="small" color="secondary" sx={{ marginBottom: "4px" }} />
                            <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                              Draw polygon to define subarea
                            </Typography>
                          </Grid>
                          <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                            <ul>
                              <li>Single-click on the map to add vertices (points defining the subarea boundary).</li>
                              <li>
                                Double-click or press <code>Enter</code> to finish; press <code>ESC</code> to cancel.
                              </li>
                              <li>
                                In case the new geometry needs to be modified follow the steps in{" "}
                                <strong>Edit subarea polygon</strong>.
                              </li>
                            </ul>
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="subtitle2" fontWeight={700}>
                            Edit subarea polygon
                          </Typography>
                          <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                            <ul>
                              <li>Single-click in the subarea polygon to select it.</li>
                              <li>Single-click on a vertex (green circles) to drag it to a new location.</li>
                              <li>Single-click on a midpoint (green-white circles) to insert a new vertex.</li>
                              <li>To delete a vertex, double-click on it. This opens a popup:</li>
                              <ul style={{ marginLeft: "2rem" }}>
                                <li>
                                  Press{" "}
                                  <Check
                                    fontSize="inherit"
                                    color="success"
                                    sx={{ marginX: 0.5, marginBottom: "-2px" }}
                                  />{" "}
                                  to delete the vertex or{" "}
                                  <Clear fontSize="inherit" color="error" sx={{ marginX: 0.5, marginBottom: "-2px" }} />{" "}
                                  to close the popup.
                                </li>
                              </ul>
                            </ul>
                          </Typography>
                        </div>
                        <div>
                          <Grid container alignItems={"center"}>
                            <SelectAll fontSize="small" color="secondary" sx={{ marginBottom: "4px" }} />
                            <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                              Cover entire area
                            </Typography>
                          </Grid>
                          <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                            <ul>
                              <li>Click the button to cover the entire parent area with the subarea polygon.</li>
                            </ul>
                          </Typography>
                        </div>
                        <div>
                          <Grid container alignItems={"center"}>
                            <Deselect fontSize="small" color="secondary" sx={{ marginBottom: "4px" }} />
                            <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                              Delete subarea
                            </Typography>
                          </Grid>
                          <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                            <ul>
                              <li>
                                Click the button to delete the subarea polygon, if you want to start from scratch.
                              </li>
                            </ul>
                          </Typography>
                        </div>
                        <div>
                          <Grid container alignItems={"center"}>
                            <MergeUnion fontSize="small" color="secondary" sx={{ marginBottom: "4px" }} />
                            <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                              Draw polygon to extend subarea
                            </Typography>
                          </Grid>
                          <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                            <ul>
                              <li>
                                Add vertices to define a polygon that will be merged with the subarea, extending it.
                              </li>
                              <ul style={{ marginLeft: "2rem" }}>
                                <li>This allows to reshape/enlarge a subarea without moving many vertices.</li>
                                <li>
                                  Vertices added near the subarea boundary are snapped (press <code>Alt</code> to
                                  disable).
                                </li>
                                <li>
                                  The polygon to extend the subarea can pass through the subarea interior without need
                                  for exact alignment.
                                </li>
                              </ul>
                              <li>
                                Double-click or press <code>Enter</code> to finish; press <code>ESC</code> to cancel.
                              </li>
                            </ul>
                          </Typography>
                        </div>
                        <div>
                          <Grid container alignItems={"center"}>
                            <MergeDifference fontSize="small" color="secondary" sx={{ marginBottom: "4px" }} />
                            <Typography variant="subtitle2" fontWeight={700} marginLeft={1}>
                              Draw polygon to remove from subarea
                            </Typography>
                          </Grid>
                          <Typography variant="body2" color={"text.secondary"} component={"div"} paddingLeft={2}>
                            <ul>
                              <li>Add vertices to define a polygon that will be subtracted from the subarea.</li>
                              <ul style={{ marginLeft: "2rem" }}>
                                <li>This allows to reshape/reduce a subarea without moving many vertices.</li>
                                <li>
                                  Vertices added near the subarea boundary are snapped (press <code>Alt</code> to
                                  disable).
                                </li>
                                <li>
                                  The polygon to subtract can pass outside of the current subarea without need for exact
                                  alignment.
                                </li>
                              </ul>
                              <li>
                                Double-click or press <code>Enter</code> to finish; press <code>ESC</code> to cancel.
                              </li>
                            </ul>
                          </Typography>
                        </div>
                      </Stack>
                    </Box>
                  </Popover>
                }
              >
                <Grid container justifyContent="space-evenly" gap={1} padding={1}>
                  <Tooltip title={"Draw polygon to define subarea"}>
                    <span>
                      <DrawIconButton
                        height={35}
                        disabled={Boolean(ODDatasetConfiguration.data?.subAreaGeometry) || isEditingGate}
                        selected={editorDrawMode === DatasetEditorMode.MergeUnion}
                        onClick={toggleUnionDrawMode}
                      >
                        {<DrawPolygon />}
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Cover entire area">
                    <span>
                      <DrawIconButton height={35} disabled={isEditingGate} onClick={() => onAddFullEntireAreaPolygon()}>
                        <SelectAll />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={"Delete subarea"}>
                    <span>
                      <DrawIconButton
                        height={35}
                        disabled={Boolean(!ODDatasetConfiguration.data?.subAreaGeometry) || isEditingGate}
                        onClick={handleDeleteSubarea}
                      >
                        <Deselect />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={"Draw polygon to extend subarea"}>
                    <span>
                      <DrawIconButton
                        height={35}
                        disabled={Boolean(!ODDatasetConfiguration.data?.subAreaGeometry) || isEditingGate}
                        selected={editorDrawMode === DatasetEditorMode.MergeUnion}
                        onClick={toggleUnionDrawMode}
                      >
                        {<MergeUnion />}
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Draw polygon to remove from subarea">
                    <span>
                      <DrawIconButton
                        height={35}
                        disabled={Boolean(!ODDatasetConfiguration.data?.subAreaGeometry) || isEditingGate}
                        selected={editorDrawMode === DatasetEditorMode.MergeDifference}
                        onClick={toggleDifferenceDrawMode}
                      >
                        <MergeDifference />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </MapControlContainer>
              <MapControlContainer
                title="Gates"
                primaryAction={
                  <VisibilityIconButton visible={showGates} onClick={changeShowGates} disabled={loading} />
                }
                secondaryAction={
                  <Badge
                    color="secondary"
                    sx={{ backgroundColor: "#fff !important", height: "auto" }}
                    label={`${ODDatasetConfiguration.data?.gates?.length || 0} gate${
                      ODDatasetConfiguration.data?.gates?.length === 1 ? "" : "s"
                    }`}
                  />
                }
              >
                <Box padding={1}>
                  <ToggleButton
                    fullWidth
                    value={"gates"}
                    color="secondary"
                    selected={isGateEditorOpen}
                    onClick={() => setIsGateEditorOpen(!isGateEditorOpen)}
                    sx={(theme) => ({
                      color: "secondary.main",
                      "&:hover": { border: `1px solid ${theme.palette.secondary.main}` },
                      "&.Mui-selected": { border: `1px solid ${theme.palette.secondary.main}` },
                    })}
                  >
                    Edit Gates
                  </ToggleButton>
                </Box>
              </MapControlContainer>

              <MapControlContainer
                title={"Roads"}
                primaryAction={
                  <VisibilityIconButton visible={showRoads} onClick={changeShowRoads} disabled={loading} />
                }
              >
                <Box padding={1}>
                  <RoadClassSelector
                    label="Road Classes for Gate Definition"
                    selectedRoadClasses={selectedRoadClasses}
                    savedRoadClasses={ODDatasetConfiguration.data?.gateRoadClasses || null}
                    setSelectedRoadClasses={setSelectedRoadClasses}
                  />
                </Box>
              </MapControlContainer>

              <MapControlContainer
                title="Zoning"
                primaryAction={
                  <VisibilityIconButton visible={showZones} onClick={changeShowZones} disabled={loading} />
                }
                secondaryAction={
                  <Badge
                    color="secondary"
                    sx={{ backgroundColor: "#fff !important", height: "auto" }}
                    label={
                      subareaState.state === DataState.LOADING
                        ? "loading..."
                        : `${subareaState.data?.zoneIds.length || 0} zone${
                            subareaState.data?.zoneIds.length === 1 ? "" : "s"
                          }`
                    }
                  />
                }
              >
                <Box padding={1}>
                  <ZoningSelector
                    activeItem={selectedZoning}
                    customZoningId={customZoningId}
                    onItemSelect={setSelectedZoning}
                    zoningOptions={zoningOptions}
                  />
                </Box>
              </MapControlContainer>
            </FieldsContainer>

            <Divider />

            <Stack direction={"row"} spacing={1}>
              {touched && (
                <>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRevertChanges(handleReset)}
                    disabled={loading || !areChangesPending}
                  >
                    Revert
                  </Button>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    disabled={loading || !areChangesPending}
                  >
                    Save
                  </Button>
                </>
              )}
              <Button
                size="medium"
                color="secondary"
                disabled={loading || !ODDatasetConfiguration.data?.permissions.compute.allow}
                onClick={handleComputeSubmit}
              >
                Compute...
              </Button>
            </Stack>

            {isUnsavedChangesBeforeCompute && (
              <ConfirmDialog
                open={isUnsavedChangesBeforeCompute}
                title="Confirm compute"
                confirmButtonText="Save and continue"
                onCancel={handleCloseConfirmSaveBeforeCompute}
                onConfirm={() =>
                  handleConfirmSaveBeforeComputeSubmit(values, {
                    setSubmitting,
                  } as unknown as FormikHelpers<DatasetForm>)
                }
              >
                <DialogContentText>
                  You have unsaved configuration changes. Do you want to save them and continue?
                </DialogContentText>
              </ConfirmDialog>
            )}
          </FormWrapper>
        )}
      </>
    );
  },
);
