import memoryStore, { MemoryStoreKeys } from "api/memoryStore";
import { debounce } from "lodash";
import { AnyLayer, Map as MapboxMap } from "mapbox-gl";
import { Dispatch, SetStateAction } from "react";

import {
  batchRemoveFeatureStates,
  batchUpdateFeatureStates,
  getVisibleFeaturesForLayer,
} from "features/map/modules/roads/map-data/handlers/utils";

import { RoadIntersectionClusterVolumes, RoadIntersectionLevel, RoadIntersectionVolumes } from "types";

import {
  ROAD_INTERSECTIONS_LAYER_ID,
  getRoadIntersectionLayerNameForLevel,
  getRoadIntersectionSourceLayerNameForLevel,
} from "../layers";
import { ROAD_INTERSECTIONS_SOURCE_ID, getRoadIntersectionSourceNameForLevel } from "../sources";

export const initVolumesSources = (
  map: MapboxMap,
  volumesDetails: RoadIntersectionVolumes,
  clusterVolumesDetails: RoadIntersectionClusterVolumes,
  levels: RoadIntersectionLevel[],
  setVolumesLoading: Dispatch<SetStateAction<boolean>>,
  setIsBaseIntersectionLevel: Dispatch<SetStateAction<boolean>>,
) => {
  let featureStateSet = new Set<number>();
  let zoom = map.getZoom();

  const getClusterLevelAtZoomLevel = (zoom: number): RoadIntersectionLevel | undefined => {
    return levels.find((level) => level.minZoomLevel <= zoom && level.maxZoomLevel > zoom);
  };

  // Finds either first cluster level or base intersection layer at current map zoom level
  // Assumes layers are not overlapping in zoom ranges
  const getIntersectionLayerAtZoomLevel = (zoom: number): AnyLayer | undefined => {
    const layers = map.getStyle().layers || [];

    return layers.find((layer: any) => {
      return (
        layer?.source?.startsWith(ROAD_INTERSECTIONS_SOURCE_ID) &&
        layer.minzoom <= zoom &&
        (!layer.maxzoom || layer.maxzoom > zoom)
      );
    });
  };

  const updateFeatureStatesByVolumes = (isForceUpdate: boolean) => {
    if (!map || (volumesDetails?.size === 0 && clusterVolumesDetails?.size === 0)) {
      setVolumesLoading(false);
      return;
    }

    // Get all visible features at current zoom level
    const currentLayer = getIntersectionLayerAtZoomLevel(map.getZoom());
    const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());
    const allVisibleFeatures = getVisibleFeaturesForLayer(map, currentLayer?.id);

    if (!currentLayer || !clusterLevel) {
      return;
    }

    const isBaseLevel = currentLayer.id === ROAD_INTERSECTIONS_LAYER_ID;
    setIsBaseIntersectionLevel(isBaseLevel);

    if ((isForceUpdate || allVisibleFeatures.length === 0) && featureStateSet.size > 0) {
      featureStateSet.clear();
    }

    // Filter out features that are already visible
    const visibleFeatures = allVisibleFeatures.filter((f) => {
      if (isForceUpdate) {
        return true;
      }

      if (featureStateSet.size > 0 && featureStateSet.has(f.id as number)) {
        return false;
      }

      return true;
    });

    if (visibleFeatures.length === 0) {
      setVolumesLoading(false);
      return;
    }

    // cluster ids are not unqiue across levels, but at each non-overlapping level they are
    const features: Map<number, { volume: number }> = new Map();

    const volumes: Map<number, number> = memoryStore.getItem(MemoryStoreKeys.ROAD_INTERSECTION_VOLUMES) || new Map();
    const clusterVolumes: Map<number, Map<number, number>> = memoryStore.getItem(
      MemoryStoreKeys.ROAD_INTERSECTION_CLUSTER_VOLUMES,
    ) || new Map();

    const idField = clusterLevel.tileService.idField;

    visibleFeatures.forEach((feature: any) => {
      const featureId = feature.properties[idField] as number;
      if (isBaseLevel) {
        features.set(featureId, { volume: volumes.get(featureId) || 0 });
      } else {
        // should always be set
        if (clusterLevel.maxRadius) {
          features.set(featureId, { volume: clusterVolumes.get(clusterLevel.maxRadius)?.get(featureId) || 0 });
        }
      }
    });

    const newFeatureStateSet = new Set(allVisibleFeatures.map((f) => f.id as number));
    const invisibleFeatures = Array.from(featureStateSet).filter((id) => !newFeatureStateSet.has(id));

    // Remove feature states that are not visible anymore
    batchRemoveFeatureStates(
      invisibleFeatures,
      ["volume"],
      map,
      getRoadIntersectionSourceNameForLevel(clusterLevel),
      getRoadIntersectionSourceLayerNameForLevel(clusterLevel),
    ).then(() => {
      // Update the feature states
      batchUpdateFeatureStates(
        Array.from(features.entries()),
        ["volume"],
        map,
        getRoadIntersectionSourceNameForLevel(clusterLevel),
        getRoadIntersectionSourceLayerNameForLevel(clusterLevel),
        setVolumesLoading,
      ).then(() => {
        zoom = map.getZoom();
      });

      // Update the feature state set
      featureStateSet = newFeatureStateSet;
    });
  };

  const updateVolumes = (forceUpdate: boolean = false) => {
    const clusterLevel = getClusterLevelAtZoomLevel(map.getZoom());

    if (!clusterLevel) {
      return;
    }

    let sourceId = getRoadIntersectionSourceNameForLevel(clusterLevel);
    let layerId = getRoadIntersectionLayerNameForLevel(clusterLevel);

    // maxRadius null or 0 means base level
    const areVolumesAvailable =
      clusterLevel.maxRadius || clusterLevel.maxRadius === 0
        ? clusterVolumesDetails?.size > 0
        : volumesDetails?.size > 0;

    const areVolumesSourceAndLayerAvailable =
      Boolean(map?.getSource(sourceId) && map?.getLayer(layerId)) && areVolumesAvailable;

    const isVolumesLayerVisible =
      areVolumesSourceAndLayerAvailable && map?.getLayoutProperty(layerId, "visibility") === "visible";

    if (!map || !isVolumesLayerVisible) {
      return;
    }

    const isZoomDiff = Math.abs(map.getZoom() - zoom) > 0.25;
    const isForceUpdate = isZoomDiff || forceUpdate === true;

    setVolumesLoading(true);

    if (isForceUpdate) {
      batchRemoveFeatureStates(
        Array.from(featureStateSet),
        ["volume"],
        map,
        getRoadIntersectionSourceNameForLevel(clusterLevel),
        getRoadIntersectionSourceLayerNameForLevel(clusterLevel),
      ).then(() => {
        updateFeatureStatesByVolumes(isForceUpdate);
      });
    } else {
      updateFeatureStatesByVolumes(isForceUpdate);
    }
  };

  const debounceUpdateVolumes = debounce(updateVolumes, 500);
  const idleDebounceUpdateVolumes = () => {
    map.once("idle", debounceUpdateVolumes);
  };

  const handleUpdateVolumesBySourceData = (e: any) => {
    if (!e.sourceId.startsWith(ROAD_INTERSECTIONS_SOURCE_ID)) {
      return;
    }

    if (!e.tile || e.tile.state !== "loaded") {
      return;
    }

    idleDebounceUpdateVolumes();
  };

  const handleUpdateVolumesByZoomEnd = () => {
    idleDebounceUpdateVolumes();
  };

  const handleInteractionEnd = () => {
    idleDebounceUpdateVolumes();
  };

  const updateRoadsIntersectionVolumes = () => {
    updateVolumes(true);
  };

  map.on("sourcedata", handleUpdateVolumesBySourceData);
  map.on("zoomend", handleUpdateVolumesByZoomEnd);
  map.on("dragend", handleInteractionEnd);
  map.on("moveend", handleInteractionEnd);

  return {
    cleanVolumesHandlers: () => {
      map.off("sourcedata", handleUpdateVolumesBySourceData);
      map.off("zoomend", handleUpdateVolumesByZoomEnd);
      map.off("dragend", handleInteractionEnd);
      map.off("moveend", handleInteractionEnd);
    },
    updateRoadsIntersectionVolumes,
  };
};
